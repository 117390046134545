import {
  ADD_RTO_CASES_REQUEST,
  EDIT_RTO_CASES_REQUEST,
  GET_RTO_CASES_REQUEST,
} from "../../types";

import { rtoCasesReqApi } from "../../../api/rtoCasesApi";

export const addRtoCases = (payload) => (dispatch) => {
  return rtoCasesReqApi.createRtoRequest(payload).then(
    (data) => {
      dispatch({
        type: ADD_RTO_CASES_REQUEST,
        payload,
      });
      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);

      return Promise.reject(error);
    }
  );
};

export const editRtoCases = (payload) => (dispatch) => {
  return rtoCasesReqApi.editRtoCasesRequest(payload).then(
    (data) => {
      dispatch({
        type: EDIT_RTO_CASES_REQUEST,
        payload: payload?.updateDetails,
      });
      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);

      return Promise.reject(error);
    }
  );
};

export const getRtoCases = (params) => (dispatch) => {
  return rtoCasesReqApi.getRtoRequestList(params).then(
    (response) => {
      // console.log("Data received jdune:", response);
      dispatch({
        type: GET_RTO_CASES_REQUEST,
        payload: response,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};
