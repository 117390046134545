import React, { useState, useEffect } from "react";
import style from "../dealers-blogs/style.module.scss";
import PrimaryHeading from "../../components/primary-heading/PrimaryHeading";
import SelectableButton from "../../components/buttons/SelectableButton";
import SearchBar from "../../components/search-bar/SearchBar";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { dealerId, R } from "../../constants/resources";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BlogTable from "./table/BlogTable";
import { blogs } from "../../api/blogs";
import { getBuyerList } from "../../store/buyerLead/buyerLeadAction";

function DealerBlogs() {
  const { total } = useSelector((state) => state.buyerLead);
  const [searchText, setSearchText] = useState({ title: "", author: "" });
  const [status, setStatus] = useState("");
  const [blogList, setBlogList] = useState([]);
  const [filteredBlogList, setFilteredBlogList] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize : 1,
    pageIndex : 1
});
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);  
  const [totalPageCount, setTotalPageCount] = useState();
  const [hasNextPage, setHasNextPage] = useState();


  const navigate = useNavigate();
  const dispatch = useDispatch();

  const dealer_id = dealerId;

  useEffect(() => {
    if (dealer_id) {
      fetchAndFilterBlogs();
    }
  }, [dealer_id, status, pagination.pageIndex]);

  useEffect(() => {
    filterBlogs();
  }, [searchText, blogList, status]); // Include status as a dependency

  const fetchAndFilterBlogs = async () => {
    try {
      const res = await blogs.getDealerBlogs({
        page : pagination.pageIndex,
        status : status,
      });

      setBlogList(res.data);
      setTotalCount(res.totalCount);
      setCurrentPage(res.currentPage);
      setTotalPageCount(res.totalPageCount);
      setHasNextPage(res.hasNextPage);
    } catch (error) {
      console.log(error);
    }
  };

  const filterBlogs = () => {
    setFilteredBlogList(
      blogList.filter((blog) => {
        const titleMatch = blog.title
          .toLowerCase()
          .includes(searchText.title.toLowerCase());

        const authorMatch = blog.author
          ? blog.author.toLowerCase().includes(searchText.author.toLowerCase())
          : false;

        const statusMatch = status === "" || blog.status === status; // Filter based on selected status

        return titleMatch && authorMatch && statusMatch;
      })
    );
  };

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchText((prev) => ({ ...prev, [name]: value }));
  };

  const handleStatusChange = (selectedValue) => {
    const newStatus = selectedValue?.target?.value || "";

    console.log("Selected Value Object:", selectedValue);
    console.log("The selected value is ", newStatus);

    setStatus(newStatus);

    // Fetch and filter blogs based on the selected status
    fetchAndFilterBlogs();
  };

  const handleNextPage = () => {
    if (hasNextPage) {
      setPagination((prev) => ({ ...prev, pageIndex: prev.pageIndex + 1 }));
    }
  };
  
  const handlePreviousPage = () => {
    if (pagination.pageIndex > 0) {
      setPagination((prev) => ({ ...prev, pageIndex: prev.pageIndex - 1 }));
    }
  };


  return (
    <div className={style["container"]}>
      <PrimaryHeading
        title={"Blogs Management"}
        fontSize={"1.75rem"}
        fontWeight={600}
        count={totalCount}
      />
      <div className={style["filters-container"]}>
        <div className={style["filters-wrapper"]}>
          {/* <SearchBar
            name="title"
            placeholder={"Blog Title"}
            handleChange={(e) => {
              handleSearchChange({
                target: {
                  name: "title",
                  value: e.target.value,
                },
              });
            }}
            value={searchText?.title || ""}
          /> */}
          <SearchBar
            name="author"
            placeholder={"Author"}
            handleChange={(e) => {
              handleSearchChange({
                target: {
                  name: "author",
                  value: e.target.value,
                },
              });
            }}
            value={searchText?.author || ""}
          />
          <SelectableButton
            trailingIcon={R.ic_arrow_down}
            containerStyle={style["selectable-button-container-style"]}
            handleChange={handleStatusChange}
            items={[
              { label: "Published", value: "1" },
              { label: "Drafts", value: "2" },
              { label: "Archived", value: "0" },
            ]}
            hint={"All Blogs"}
            value={status}
          />
        </div>
        <div className={style["action-buttons-wrapper"]}>
          <PrimaryButton
            containerStyle={style["add-blogs-button"]}
            leadingIcon={R.ic_add_white}
            name={"Add New Blog"}
            fontSize={"1rem"}
            linkTo={"/create-blogs"}
            onClick={() => navigate("/create-blogs")}
          />
        </div>
      </div>
      <BlogTable
        data={filteredBlogList}
        pagination={pagination}
        setPagination={setPagination}
        total={total}
        fetchBlogs={fetchAndFilterBlogs}
      />

      <div className={style["pagination-action-wrapper"]}>
        <button onClick={handlePreviousPage} disabled={currentPage == 1}>
          Back
        </button>
        <p>
          Page {currentPage} of {totalPageCount}
        </p>

        <button onClick={handleNextPage} disabled={!hasNextPage}>
          Next
        </button>
      </div>

    </div>
  );
}

export default DealerBlogs;
