import style from "./style.module.scss";
import { Form, Formik } from "formik";
import { InputField } from "../../../components/InputField";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../../components/buttons";
import { rtoCasesReqApi } from "../../../api/rtoCasesApi";
import { useEffect, useState } from "react";
// import { isAllowed } from "../../../util/Permissions.ts";
import { R } from "../../../constants/resources.js";
import BackButton from "../../../components/buttons/BackButton.jsx";
// import { MAIN_ROUTES, getAbsolute } from "../../../constants/routes.js";

const ViewRTOCases = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (!isAllowed("RTO Cases", "View")) navigate(-1);
  // }, []);

  const [codeList, setCodeList] = useState([]);
  const getCodeList = async () => {
    const res = await rtoCasesReqApi.getRtoCodeList();
    setCodeList(res?.data);
  };

  useEffect(() => {
    getCodeList();
  }, []);

  return (
    <>
      <div
        style={{
          marginTop: "20px",
        }}
        className={style["header-row"]}
      >
        <BackButton onClick={() => navigate("/rto-management")} />
        <p>{state?.rc_no} - RTO Case Details</p>
        {state?.status_name?.name != "Completed" &&
          state?.status_name?.name !== "Cancelled" && (
            <button
              className={style["reset-button"]}
              onClick={() => navigate("/rto-management-edit", { state })}
            >
              Edit
            </button>
          )}
      </div>
      <div className={style["container"]}>
        <Formik
          enableReinitialize
          initialValues={{}}
          validationSchema={{}}
          onSubmit={() => {}}
        >
          {({}) => (
            <Form className={style["form-container"]}>
              <div className={style["wrapper"]}>
                <div className={style["input-wrapper"]}>
                  <InputField
                    type={""}
                    label={"Dealership"}
                    hint={"Enter Dealership"}
                    value={state?.dealership_name}
                    disabled
                  />
                </div>
                <div className={style["input-wrapper"]}>
                  <InputField
                    type={""}
                    label={"Dealer Name"}
                    hint={"Enter Dealer Name"}
                    value={state?.dealership_name}
                    disabled
                  />
                </div>
                <div className={style["input-wrapper"]}>
                  <InputField
                    type={""}
                    label={"Vehicle No"}
                    hint={"Enter Vehicle Number"}
                    value={state?.rc_no}
                    disabled
                  />
                </div>
                <div className={style["input-wrapper"]}>
                  <InputField
                    type={""}
                    label={"Authority From"}
                    hint={"Enter Authority"}
                    value={
                      codeList?.find((ele) => ele.id == state?.auth_from)?.regNo
                    }
                    disabled
                  />
                </div>
                <div className={style["input-wrapper"]}>
                  <InputField
                    type={""}
                    label={"Authority To"}
                    hint={"Enter Authority"}
                    value={
                      codeList?.find((ele) => ele.id == state?.auth_to)?.regNo
                    }
                    disabled
                  />
                </div>
                <div className={style["input-wrapper"]}>
                  <InputField
                    type={""}
                    label={"Buyer Name"}
                    hint={"Enter Buyer Name"}
                    value={state?.buyer_name}
                    disabled
                  />
                </div>
                <div className={style["input-wrapper"]}>
                  <InputField
                    type={""}
                    label={"Buyer Mobile"}
                    hint={"Enter Mobile Number"}
                    value={state?.buyer_phone}
                    disabled
                  />
                </div>
                <div className={style["input-wrapper"]}>
                  <InputField
                    type={""}
                    label={"Seller Name"}
                    hint={"Enter Seller Name"}
                    value={state?.seller_name}
                    disabled
                  />
                </div>
                <div className={style["input-wrapper"]}>
                  <InputField
                    type={""}
                    label={"Seller Number"}
                    hint={"Enter Seller Number"}
                    value={state?.seller_phone}
                    disabled
                  />
                </div>
                <div className={style["input-wrapper"]}>
                  <InputField
                    type={""}
                    label={"Task"}
                    hint={"Select Tasks"}
                    value={state?.task_name?.name}
                    disabled
                  />
                </div>
                <div className={style["input-wrapper"]}>
                  <InputField
                    type={""}
                    label={"Additional Task"}
                    hint={"Select Additional Tasks"}
                    value={state?.add_task_name?.name}
                    disabled
                  />
                </div>
                <div className={style["input-wrapper"]}>
                  <InputField
                    type={""}
                    label={"Admin Assigned"}
                    hint={"Admin Assigned"}
                    value={state?.admin_assigned_name}
                    disabled
                  />
                </div>
                <div className={style["input-wrapper"]}>
                  <InputField
                    type={""}
                    label={"Remarks"}
                    hint={"Enter Remarks"}
                    value={state?.remarks}
                    disabled
                  />
                </div>
                <div className={style["input-wrapper"]}>
                  <InputField
                    type={""}
                    label={"Admin Assigned"}
                    hint={"Admin Assigned"}
                    value={state?.admin_assigned_name}
                    disabled
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ViewRTOCases;
