import { serviceHistoryCall } from ".";

class VahanCheckRequest {
  getVahanCheckList(params) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await serviceHistoryCall(
            "get",
            "dealer-app/ds-service/get-vehicle-history",
            params,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getvehicleDetails(params) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await serviceHistoryCall(
            "get",
            "dealer-app/ds-service/get-vehicle",
            params,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getvehicleDetailsWithoutDealer(params) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await serviceHistoryCall(
            "get",
            "dealer-app/ds-service/get-vehicle",
            params,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
}

export const VahanCheckRequestApi = new VahanCheckRequest();
