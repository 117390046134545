import React, { useState, useEffect } from "react";
import style from "./sellerLead.module.scss";
import PrimaryHeading from "../../components/primary-heading/PrimaryHeading";
import SearchBar from "../../components/search-bar/SearchBar";
import SelectableButton from "../../components/buttons/SelectableButton";
import { R } from "../../constants/resources";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import SellerTable from "./components/table/SellerTable";
import { useDispatch, useSelector } from "react-redux";
import { getSellerList } from "../../store/sellerLead/sellerLeadAction";

export default function SellerLead() {
  // const data = [
  //   {
  //     lead_id: 1,
  //     seller_name: "kjjgjdfjgfdjgfhgfkhkj",
  //     phone_no: "8171635169",
  //     brand_id: 2,
  //     model: "Seltos",
  //     variant: "VXI",
  //     selling_price: 232323,
  //     quote_price: 343434,
  //     status: "PENDING",
  //     created_ts: "2024-05-24T13:42:47.000Z",
  //     updated_ts: "2024-05-24T13:42:47.000Z",
  //     brandDetails: {
  //       id: 2,
  //       brand_name: "Maruti",
  //       logo_image_url: "",
  //       created_ts: "2024-05-24T13:54:01.000Z",
  //       updated_ts: "2024-05-24T13:54:01.000Z",
  //     },
  //   },
  //   {
  //     lead_id: 2,
  //     seller_name: "kjjgjdfjgfdjgfhgfkhkj",
  //     phone_no: "8171645678",
  //     brand_id: 2,
  //     model: "Seltos",
  //     variant: "VXI",
  //     selling_price: 232323,
  //     quote_price: 343434,
  //     status: "PENDING",
  //     created_ts: "2024-05-24T14:04:57.000Z",
  //     updated_ts: "2024-05-24T14:04:57.000Z",
  //     brandDetails: {
  //       id: 2,
  //       brand_name: "Maruti",
  //       logo_image_url: "",
  //       created_ts: "2024-05-24T13:54:01.000Z",
  //       updated_ts: "2024-05-24T13:54:01.000Z",
  //     },
  //   },
  // ];

  const { sellerLeadList, currentPage, totalPageCount, totalCount, hasNextPage, total } = useSelector((state) => state.sellerLead);

  console.log('currentPage -->', currentPage);
  console.log('totalPageCount -->', totalPageCount);
  console.log('totalCount -->', totalCount);
  console.log('hasNextPage --->', hasNextPage);





  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  
  const [pagination, setPagination] = useState({
    pageIndex: 1, 
    pageSize: 0,
  });
  
  const dispatch = useDispatch();


  const handleChange = (e) => {
    setSearchText(e.target.value);
  };

  const [data, setData] = useState(sellerLeadList || []);

  useEffect(() => {
    dispatch(getSellerList());
  }, []);

  // useEffect(() => {
  //   setData(sellerLeadList);
  // }, [sellerLeadList]);

  
  useEffect(() => {
    setPagination({pageIndex: 1, pageSize: 0});
  }, []);
  
  

  const exportToExcel = () => {
    const dataWithoutAction = data.map(({ action, ...rest }) => rest);
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataWithoutAction);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      xlsx.writeFile(workbook, "seller_data.xlsx");
    });
  };

  useEffect(() => {
    dispatch(getSellerList({page: pagination.pageIndex, status, search: searchText }));
  }, [pagination, status, searchText, dispatch]);

  // useEffect(() => {
  //   let filterData = data;

  //   if (searchText) {
  //     filterData = data.filter((ele) =>
  //       ele.name.toLowerCase().includes(searchText.toLowerCase())
  //     );
  //   }

  //   if (status) {
  //     filterData = filterData.filter((ele) => ele.status === status);
  //   }

  //   if (!searchText && !status) {
  //     filterData = sellerLeadList;
  //   }

  //   setData(filterData);
  // }, [searchText, status]);



  const handleNextPage = () => {
    if (hasNextPage) {
      setPagination((prev) => ({ ...prev, pageIndex: prev.pageIndex + 1 }));
    }
  };
  
  const handlePreviousPage = () => {
    if (pagination.pageIndex > 0) {
      setPagination((prev) => ({ ...prev, pageIndex: prev.pageIndex - 1 }));
    }
  };



  return (
    <div className={style["container"]}>
      <PrimaryHeading
        title={"Seller Lead Management"}
        count={totalCount}
        fontSize={"1.75rem"}
        fontWeight={600}
      />

      <div className={style["filters-container"]}>
        <div className={style["filters-wrapper"]}>
          <SearchBar
            placeholder={"Seller Name or Phone"}
            handleChange={handleChange}
          />
          <SelectableButton
            containerStyle={style["selectable-button-container-style"]}
            trailingIcon={R.ic_arrow_down_blue}
            items={[
              { label: "Booked", value: "booked" },
              { label: "Already Bought", value: "already_bought" },
              { label: "Not Interested", value: "not_interested" },
              { label: "Loan Not Approved", value: "loan_not_approved" },
              { label: "Pending", value: "PENDING" },
              { label: "Done", value: "done" },
              { label: "Active", value: "ACTIVE" },
            ]}
            handleChange={(e) => setStatus(e.target.value)}
            value={status}
            hint={"All Status"}
          />
        </div>

        <div className={style["action-buttons-wrapper"]}>
          <PrimaryButton
            containerStyle={style["excel-button-container-style"]}
            leadingIcon={R.ic_excel}
            name={"Export Excel"}
            fontSize={"1rem"}
            onClick={exportToExcel}
          />
        </div>
      </div>
      <SellerTable
        data={sellerLeadList}
        pagination={pagination}
        setPagination={setPagination}
        total={sellerLeadList?.length || 0}
      />
      


      <div className={style["pagination-action-wrapper"]}>
        <button onClick={handlePreviousPage} disabled={currentPage == 1}>
          Back
        </button>
        <p>
          Page {currentPage} of {totalPageCount}
        </p>

        <button onClick={handleNextPage} disabled={!hasNextPage}>
          Next
        </button>
      </div>

    </div>
  );
}
