import { serviceHistoryCall, serviceRtoCall } from ".";

class RtoCasesApi {
  createRtoRequest(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await serviceHistoryCall(
            "post",
            "dealer-app/rto-service/create-rto-cases",
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  editRtoRequest(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await serviceHistoryCall(
            "put",
            "dealer-app/rto-service/edit-rto-cases",
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getRtoRequestList(params) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await serviceHistoryCall(
            "get",
            "dealer-app/rto-service/list-rto-cases",
            params,
            null,
            token
          );
          // console.log("Data received from API:", res.data);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getDSServiceList() {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await serviceHistoryCall(
            "get",
            "dealer-app/rto-service/doorsteps-services-lists",
            null,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getRtoStatusFilter(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await serviceHistoryCall(
            "get",
            "dealer-app/rto-service/master-rto-statuses",
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getRtoTaskList() {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await serviceHistoryCall(
            "get",
            "dealer-app/rto-service/master-rto-tasks",
            null,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getRtoAdditionalTaskList() {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await serviceHistoryCall(
            "get",
            "dealer-app/rto-service/master-add-rto-tasks",
            null,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getRtoCodeList(reqData) {
    let reqParam = { state_code: reqData };
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await serviceHistoryCall(
            "post",
            "dealer-app/rto-service/master-rto-codes",
            null,
            reqParam,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  editRtoCasesRequest(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await serviceHistoryCall(
            "put",
            "dealer-app/rto-service/edit-rto-case",
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  /**
   *  This api used for get RTO price
   * @returns
   */
  getRtoPrice(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await serviceRtoCall(
            "post",
            "dealer-app/rto-service/get-rto-tasks-pricing",
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getFromRtoCode(reqData) {
    const token = localStorage.getItem("accessToken");
    let reqParam = { state_code: reqData };
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await serviceHistoryCall(
            "post",
            "dealer-app/rto-service/master-from-rto-codes",
            null,
            reqParam,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getStateList() {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await serviceHistoryCall(
            "get",
            "dealer-app/rto-service/state-list",
            null,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getCityList(reqData) {
    let reqParam = { state_code: reqData };
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await serviceHistoryCall(
            "post",
            "dealer-app/rto-service/city-list",
            null,
            reqParam,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getAutoTaskSuggestion(reqData) {
    let reqParam = {
      currentRTO: "DL",
      destinationRTO: "DL",
      rcFinancer: "no",
      loanAddition: "no",
      noc: "no",
      hpa: "no",
      hpt: "no",
      state: "DL",
    };

    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await serviceHistoryCall(
            "post",
            "dealer-app/rto-service/getAutoTaskSuggestion",
            null,
            reqData,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
}

export const rtoCasesReqApi = new RtoCasesApi();
