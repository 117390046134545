export const get_url_extension = (url) => {
  return url?.split(/[#?]/)[0].split(".").pop().trim();
};

export const downloadFile = async (url, extension) => {
  const response = await fetch(url, { mode: "no-cors" });
  const blob = await response.blob();
  const fileUri = URL.createObjectURL(blob);

  const anchor = document.createElement("a");
  anchor.href = fileUri;
  anchor.target = "_blank";
  anchor.download = `file.${extension}`;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

export const isImage = (extension) =>
  ["png", "jpeg", "jpg", "gif"].includes(extension);

// Generate service amount through agreed price
// export const generateServicePrice = (data = {}) => {
//   const base_consultancy_calc_factor = 1.18;
//   const gst_calc_factor = 0.18;

//   const { agreed_amount, amount, isGst } = data;

//   // Check required validation
//   if (!(agreed_amount && amount && parseInt(agreed_amount) > parseInt(amount)))
//     return {
//       consultancy: 0,
//       base_consultancy: 0,
//       gst_amount: 0,
//       total_amount: 0,
//     };

//   const consultancy = agreed_amount - amount;
//   const base_consultancy =
//     isGst === "GST_Included"
//       ? Math.ceil(consultancy / base_consultancy_calc_factor)
//       : Math.ceil(consultancy);
//   const gst_amount =
//     isGst === "GST_Not_Applicable"
//       ? 0
//       : Math.ceil(base_consultancy * gst_calc_factor);
//   const total_amount = Math.ceil(
//     parseInt(amount) + parseInt(base_consultancy) + parseInt(gst_amount)
//   );

//   return {
//     consultancy,
//     base_consultancy,
//     gst_amount,
//     total_amount,
//   };
// };

export const generateServicePrice = (data = {}) => {
  const base_consultancy_calc_factor = 1.18;
  const gst_calc_factor = 0.18;

  const { agreed_amount, amount, isGst } = data;

  // Check required validation
  if (!(agreed_amount && amount && parseInt(agreed_amount) >= parseInt(amount)))
    return {
      consultancy: 0,
      base_consultancy: 0,
      gst_amount: 0,
      total_amount: 0,
    };

  const consultancy = agreed_amount - amount;
  const base_consultancy =
    isGst === "GST_Included"
      ? consultancy / base_consultancy_calc_factor
      : consultancy;
  const gst_amount =
    isGst === "GST_Not_Applicable" ? 0 : base_consultancy * gst_calc_factor;
  const total_amount =
    parseFloat(amount) + parseFloat(base_consultancy) + parseFloat(gst_amount);

  return {
    consultancy,
    base_consultancy: parseFloat(base_consultancy.toFixed(2)),
    gst_amount: parseFloat(gst_amount.toFixed(2)),
    total_amount: parseFloat(total_amount.toFixed(2)),
  };
};

export const generateCreativePrice = (data = {}) => {
  const gst_calc_factor = 0.18;
  const base_consultancy_calc_factor = 1.18;

  const { agreed_amount, isGst, count, consultancy } = data;

  // Check required validation
  const base_consultancy =
    isGst === "GST_Included"
      ? agreed_amount / base_consultancy_calc_factor
      : agreed_amount;
  const gst_amount =
    isGst === "GST_Not_Applicable" ? 0 : base_consultancy * gst_calc_factor;
  const total_amount =
    isGst === "GST_Included"
      ? parseFloat(agreed_amount)
      : parseFloat(agreed_amount) + parseFloat(gst_amount);

  return {
    agreed_amount,
    gst_amount: parseFloat(gst_amount.toFixed(2)),
    total_amount: parseFloat(total_amount.toFixed(2)),
  };
};

export const calculateTotalAmount = (data = {}) => {
  const gst_calc_factor = 0.18;
  const { agreed_amount, gst_type } = data;

  if (!agreed_amount) {
    return {
      total_amount: 0,
    };
  }

  let total_amount = 0;

  if (gst_type === "GST_Included") {
    total_amount = agreed_amount;
  } else if (gst_type === "GST_Excluded") {
    total_amount = agreed_amount * (1 + gst_calc_factor);
  } else if (gst_type === "GST_Not_Applicable") {
    total_amount = agreed_amount;
  }

  return {
    total_amount: parseFloat(total_amount),
  };
};



export const formatIndianCurrency = (amount) => {
  if (!amount) return "";

  const x = amount.toString().replace(/\D/g, "");

  const lastThree = x.substring(x.length - 3);
  const otherNumbers = x.substring(0, x.length - 3);

  const formattedValue =
    otherNumbers !== ""
      ? otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThree
      : lastThree;

  return formattedValue;
};