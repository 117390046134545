import axios from "axios";
import {
  call,
  customerCall,
  dealerCall,
  dealerPrefrencesCall,
  getDealerCallPrefrence,
  UpdateDealerUploadDocument,
  uploadedDocumentDealerApi,
  addManageStore,
  getRtoAgentsCall,
} from "./";
import { Navigate, useNavigate } from "react-router";
import { showToast } from "../components/toaster/Toaster";

class DealerApi {
  addDealerPrefrence(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerPrefrencesCall(
            "post",
            "admin/buyer-lead/dealer-preferences",
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  addBasicDeatils(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerCall(
            "post",
            "admin-dealer-auth/create-dealer-basic-details",
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  addCustomer(data) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await customerCall(
            "post",
            "/admin/customers",
            null,
            data
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  addDocuments(data) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await customerCall(
            "post",
            `/admin/customers/:customer_id/documents`,
            null,
            data
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getdealertypeDocument(data) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await uploadedDocumentDealerApi(
            "get",
            "/dealers_master_document",
            null,
            data
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  delerdocumentUpload(data, dealer_id) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await uploadedDocumentDealerApi(
            "post",
            `/dealers_upload_documents/${dealer_id}`,
            null,
            data
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  addKycDetails(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerCall(
            "put",
            "admin-dealer-auth/edit-dealer-kyc",
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  addBankDetails(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerCall(
            "put",
            "admin-dealer-auth/edit-dealer-bankdetails",
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  editBasicDeatils(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerCall(
            "put",
            "admin-dealer-auth/edit-dealer-basic-details",
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  // addCreditDetails(data) {
  //   const token = localStorage.getItem("accessToken");
  //   return new Promise((resolve, reject) => {
  //     (async () => {
  //       try {
  //         const res = await dealerCall(
  //           "post",
  //           "admin-dealer-auth/add-dealer-cl-sp",
  //           null,
  //           data,
  //           token
  //         );
  //         resolve(res.data);
  //       } catch (err) {
  //         console.log(err);
  //         reject(err);
  //       }
  //     })();
  //   });
  // }

  addCreditDetails(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerCall(
            "post",
            "admin-dealer-auth/add-dealer-clsp",
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  editUser(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerCall(
            "put",
            "admin-dealer-auth/edit-dealer",
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  // getDealers(params) {
  //   const token = localStorage.getItem("accessToken");
  //   return new Promise((resolve, reject) => {
  //     (async () => {
  //       try {
  //         const res = await dealerCall(
  //           "get",
  //           "admin-dealer-auth/get-all-dealers",
  //           params,
  //           null,
  //           token
  //         );
  //         resolve(res.data);
  //       } catch (err) {
  //         console.log(err);
  //         reject(err);
  //       }
  //     })();
  //   });
  // }

  getDealersList(agent_id, type) {
    const params = {
      agent_id: agent_id,
      type: type,
    };
    console.log("params", params);

    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await getRtoAgentsCall(
            "get",
            "dealer-app/rto-service/get-rto-agent-contacts",
            params,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getDealerStatusFilter(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerCall(
            "get",
            "admin-dealer-auth/get-dealer-statuses-master",
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getDataByIfsc(ifsc) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerCall(
            "get",
            `admin-dealer-auth/get-ifsc-Details?ifsc=${ifsc}`,
            null,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getService() {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerCall(
            "get",
            `admin-dealer-auth/get-dealer-services-master`,
            null,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getBasicDetails(id) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerCall(
            "get",
            `admin-dealer-auth/get-dealer-basic-details?dealer_id=${id}`,
            null,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getKycDetails(id) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerCall(
            "get",
            `admin-dealer-auth/get-dealer-kyc?dealer_id=${id}`,
            null,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getBankDetails(id) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerCall(
            "get",
            `admin-dealer-auth/get-dealer-bankdetails?dealer_id=${id}`,
            null,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  // getCreditDetails(id) {
  //   const token = localStorage.getItem("accessToken");
  //   return new Promise((resolve, reject) => {
  //     (async () => {
  //       try {
  //         const res = await dealerCall(
  //           "get",
  //           `admin-dealer-auth/get-dealer-cl-sp?dealer_id=${id}`,
  //           null,
  //           null,
  //           token
  //         );
  //         resolve(res.data);
  //       } catch (err) {
  //         console.log(err);
  //         reject(err);
  //       }
  //     })();
  //   });
  // }

  getDelarUploadDocuments(dealer_id) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await uploadedDocumentDealerApi(
            "get",
            `/dealers_Upload_Document_Get/${dealer_id}`
          );
          resolve(res.data);
        } catch (error) {
          reject(error);
        }
      })();
    });
  }

  deleteDealersDocuments(id, body) {
    console.log("deleteDealersDocuments ==> ", id, body);
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await UpdateDealerUploadDocument(
            "PUT",
            `/deleteDocuments/${id}`,
            {},
            body
          );
          resolve(res);
        } catch (error) {
          reject(error);
        }
      })();
    });
  }

  getCreditDetails(id) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerCall(
            "get",
            `admin-dealer-auth/get-dealer-clsp?dealer_id=${id}`,
            null,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getWalletAmount(id) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerCall(
            "get",
            `admin-dealer-auth/get-dealer-wallet-data?dealer_id=${id}`,
            null,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getWalletCoin(id) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerCall(
            "get",
            `admin-dealer-auth/get-dealer-coins?dealer_id=${id}`,
            null,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  // getDealerCoins(dealer_id, service_id) {
  //   const token = localStorage.getItem("accessToken");
  //   return new Promise((resolve, reject) => {
  //     (async () => {
  //       try {
  //         const res = await dealerCall(
  //           "get",
  //           `admin-dealer-auth/get-dealer-coins?dealer_id=${dealer_id}&service_id=${service_id}`,
  //           null,
  //           null,
  //           token
  //         );
  //         resolve(res.data);
  //       } catch (err) {
  //         console.log(err);
  //         reject(err);
  //       }
  //     })();
  //   });
  // }

  getDealerPrefrence(param) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await getDealerCallPrefrence(
            "get",
            `admin/buyer-lead/get-dealer/${param}`,
            token
          );
          // console.log("hi Janmejay ", res.data)
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
  saveManageStore = (dealer_id, data) => {
    console.log("dealer_id --->", dealer_id);

    return new Promise(async (resolve, reject) => {
      try {
        const res = await addManageStore(
          "post",
          `save-store?dealer_id=${dealer_id}`,
          data
        );
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  };

  getState(pincode) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await addManageStore("get", "get-state", { pincode }, {});
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  }

  getManageStore(dealer_id) {
    console.log("dealer-id-manage-store --->", dealer_id);

    return new Promise(async (resolve, reject) => {
      try {
        const res = await addManageStore(
          "get",
          `get-manage-store?dealer_id=${dealer_id}`,
          null
        );
        console.log("data --->", res);
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  }

  updateIsActive(id, dealer_id, newActiveState) {
    const params = {
      dealer_id,
      id,
    };
    return new Promise(async (resolve, reject) => {
      try {
        const res = await addManageStore(
          "post",
          "is_active",
          { newActiveState },
          params
        );
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  }

  removeManageStore(dealer_id, id) {
    console.log(`your dealer_id is ${dealer_id} and remove id is ${id}`);

    return new Promise(async (resolve, reject) => {
      try {
        const res = await addManageStore(
          "put",
          `remove-store?dealerid=${dealer_id}`,
          { id }
        );
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  }

  saveWebsiteHeading(id, data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerCall(
            "post",
            `admin-dealer-auth/save-dealer-seo`,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getWebsiteHeading(id) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerCall(
            "get",
            `admin-dealer-auth/delaer-seo-info?dealerId=${id}`,
            null,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  saveDealerAgent(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await getRtoAgentsCall(
            "post",
            `dealer-app/rto-service/rto-agent-contacts`,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
}

export const dealerApi = new DealerApi();
