export const Regex = {
  PASSWORD:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&])[a-zA-Z0-9@#$%^&]{8,16}$/,
  PAN: /^[A-Z]{5}[0-9]{4}[A-Z]$/,
  GSTIN: /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1})$/,
  AADHAAR: /^\d{12}$/,
  DEALER_NAME: /^[A-Za-z0-9.,\-&’() ]{1,30}$/,
  DEALERSHIP_NAME: /^[A-Za-z0-9.,\-&’() ]{1,30}$/,
  ADDRESS: /^[A-Za-z0-9.,\-&’()#/' ]{1,250}$/,
  NAME: /^[a-zA-Z ]+$/,
  MOBILE: /^\d{10}$/,
  PINCODE: /^[1-9][0-9]{5}$/,
  IFSC: /^[A-Z]{4}0[A-Z0-9]{6}$/,
  ACCOUNT_NUMBER: /^\d{9,20}$/,
  // ENGINE_NUMBER: /^[a-zA-Z0-9]{12}$/,
  // CHESSIS_NUMBER: /^[a-zA-Z0-9]{17}$/,
  VEHICLE_NUMBER:
    /^(?:[A-Z]{2}\d{1,2}[A-Z]{2,3}\d{3,4}|[A-Z]{2}[A-Z]{2}[A-Z]{2}\d{4}|CD\d{5}|TR\d{6}|A\d{5}|\d{2}BH\d{4}[A-Z]{1,2}|[A-Z]{2}\d{6}|[A-Z]{2}\d{1,2}[A-Z]{1,2}\d{4})$/,
  POC_NAME: /^[A-Za-z ]$/,
  AUTHORIZE: /^[A-Za-z]{2}\d[A-Za-z0-9]$/,
  UTR: /^[a-zA-Z0-9]{1,25}$/,
  DECIMAL_NUMBER: /^\d+(\.\d{0,2})?$/,
  NUMBER: /^\d+$/,
};
