import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const DEFAULT_FILENAME = "Motor_Pedia_" + new Date().toDateString();
const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

/**
 *  Export table data if Tanstack table instance is passed,
 *  It export original value fetched from backend
 * @param table
 * @param fileName
 * @param sheetName
 * @param columns
 */
export const exportTableToExcel = (
  table,
  fileName = DEFAULT_FILENAME,
  sheetName = "Sheet 1",
  columns
) => {
  const selectedColumns = columns
    ? columns
    : Array.from(
        { length: table.getHeaderGroups().at(-1).headers.length },
        (_, index) => index + 1
      );

  const rowArray = table.getPrePaginationRowModel().rows.map((row) => {
    return row.getAllCells().reduce((resultArray, cell, index) => {
      if (selectedColumns.includes(index + 1))
        resultArray.push(cell.getValue());
      return resultArray;
    }, []);
  });
  const headerArray = table
    .getHeaderGroups()
    .at(-1)
    .headers.reduce((resultArray, header, index) => {
      if (selectedColumns.includes(index + 1))
        resultArray.push(header.column.columnDef.header);
      return resultArray;
    }, []);
  exportData(headerArray, rowArray, fileName, sheetName);
};

/**
 * Exports the table by iterating whole table as it is visible currently,
 * In-short, Exported data will be same as what you can see data visually in table
 * @param table
 * @param fileName
 * @param sheetName
 * @param cellClassName
 * @param headerClassName
 */

export const exportVisibleTableToExcel = (
  table,
  fileName = DEFAULT_FILENAME,
  sheetName = "Sheet 1",
  cellClassName = "exportable-cell",
  headerClassName = "exportable-heading"
) => {
  const rowArray = [];
  const headerArray = [];
  for (const row of table.rows) {
    for (const cell of row.cells) {
      if (cell.className === headerClassName) headerArray.push(cell.innerText);
    }
  }
  for (const row of table.rows) {
    let currentRowArray = [];
    for (const cell of row.cells) {
      let cellValue =
        cell.querySelector(`.${cellClassName}`)?.innerText ?? null;
      if (cellValue !== null) currentRowArray.push(cellValue);
    }
    if (currentRowArray.length > 0) rowArray.push(currentRowArray);
  }
  exportData(headerArray, rowArray, fileName, sheetName);
};

/**
 *
 * @param headerArray
 * @param rowArray
 * @param fileName
 * @param sheetName
 */
export const exportData = (headerArray, rowArray, fileName, sheetName) => {
  if (headerArray.length < 1 || rowArray.length < 1) return;

  const sheet = XLSX.utils.json_to_sheet(rowArray);
  let wscols = [];
  headerArray.map((arr) => wscols.push({ wch: arr.length + 5 }));
  sheet["!cols"] = wscols;

  XLSX.utils.sheet_add_aoa(sheet, [headerArray], {
    origin: "A1",
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, sheet, sheetName);

  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};
