import style from "./style.module.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { getAbsolute, MAIN_ROUTES } from "../../constants/routes";
// import { isAllowed } from "../../util/Permissions.ts";
import { showToast } from "../../components/toaster/Toaster";
import { useNavigate } from "react-router-dom";
import { R } from "../../constants/resources";
import PermissionPlaceholder from "../../components/PermissionPlaceholder/PermissionPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { getRtoCases } from "../../store/rto-services/rtoCases/rtoCasesAction.js";
import { rtoCasesReqApi } from "../../api/rtoCasesApi.js";
import { dealerApi } from "../../api/dealerApi.js";
import moment from "moment";
import { exportVisibleTableToExcel } from "../../utils/ExportUtil.js";
import Select from "react-select";
import debounce from "lodash/debounce";
import { LoadingModal } from "../../components/LoadingModal/index.jsx";
import PrimaryHeading from "../../components/primary-heading/PrimaryHeading.jsx";
import SearchBar from "../../components/search-bar/SearchBar.jsx";
import PrimaryButton from "../../components/buttons/PrimaryButton.jsx";

const RtoCases = () => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const rtoCasesRequestList = useSelector(
    (state) => state?.rtoCasesReducer?.rtoCasesRequestList?.data || []
  );
  const total = useSelector(
    (state) => state?.rtoCasesReducer?.rtoCasesRequestList?.total || []
  );

  const dispatch = useDispatch();
  const toDateInputRef = useRef(null);
  const fromDateInputRef = useRef(null);
  const [status, setStatus] = useState([]);
  const [search, setSearch] = useState("");
  const [task_id, setTaskId] = useState([]);
  const [end_date, setEndDate] = useState("");
  const [start_date, setStartDate] = useState("");
  const [dealership, setDealerShip] = useState([]);
  const [filterStatus, setFilterStatus] = useState([]);
  const [filterTask, setFilterTask] = useState([]);
  const [filterDealer, setFilterDealer] = useState([]);

  const debouncedChangeHandler = useCallback(
    debounce((e) => {
      let { value, id } = e.target;
      if (id === "search") {
        setSearch(value);
        handelPagination();
      }
    }, 700),
    []
  );

  const [loading, setLoading] = useState(false);

  const onGetDoorStep = () => {
    setLoading(true);
    dispatch(
      getRtoCases({
        page: pagination.pageIndex + 1,
        pagesize: pagination.pageSize,
        task_id: task_id?.join(",") || undefined,
        dealership: dealership?.join(",") || undefined,
        search: search || undefined,
        start_date: start_date || undefined,
        end_date: end_date || undefined,
        status: status?.join(",") || undefined,
      })
    )
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handelPagination = () => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: 0,
    }));
  };

  // useEffect(() => {
  //   setPagination((prev) => ({
  //     ...prev,
  //     pageIndex: 0,
  //   }));
  // }, [dealership, task_id, search, start_date, end_date, status]);

  useEffect(() => {
    onGetDoorStep();
  }, [pagination]);

  const columnDefinition = [
    {
      header: "Case No",
      accessorKey: "case_id",
      exportAllowed: true,
    },
    {
      header: "Veh. No",
      accessorKey: "rc_no",
      exportAllowed: true,
    },
    {
      accessorFn: (row) => {
        const dealershipName = row?.dealership_name ?? "-";
        const dealerCode = row?.dealercode ?? "-";
        return `${dealershipName} | ${dealerCode}`;
      },
      header: "Dealership",
      cell: (info) => info.getValue(),
      exportAllowed: true,
    },
    {
      header: "Dealer Name",
      accessorFn: (row) => {
        const dealerName = row?.dealer_name ?? "-";
        const dealerNumber = row?.dealership_mobile ?? "-";
        return { dealerName, dealerNumber };
      },
      cell: (info) => {
        const { dealerName, dealerNumber } = info.getValue();
        const tooltipId = `dealer-tooltip-${info.cell.id}`;
        return (
          <div>
            <span data-tooltip-id={tooltipId}>{dealerName}</span>
            <ReactTooltip id={tooltipId} place="right" variant="dark">
              {dealerNumber}
            </ReactTooltip>
          </div>
        );
      },
      exportAllowed: true,
    },
    {
      header: "Task",
      accessorKey: "task_name.name",
      exportAllowed: true,
    },
    {
      header: "Status",
      accessorKey: "status_name.name",
      exportAllowed: true,
    },
    {
      header: "Action",
      accessorKey: "action",
    },
  ];

  const table = useReactTable({
    data: rtoCasesRequestList,
    columns: columnDefinition,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
    autoResetPageIndex: false,
  });

  const getRTOStatus = async () => {
    const res = await rtoCasesReqApi.getRtoStatusFilter();
    setFilterStatus(res?.data);
  };

  const getRTOTask = async () => {
    const res = await rtoCasesReqApi.getRtoTaskList();
    setFilterTask(res?.data);
  };

  // const getRTODealer = async () => {
  //   const res = await dealerApi.getDealers({ page: 1, pagesize: 500 });
  //   setFilterDealer(res?.data);
  // };

  useEffect(() => {
    getRTOStatus();
    getRTOTask();
    // getRTODealer();
  }, []);

  const onViewCaseClick = (data) => {
    navigate("/rto-management-view", {
      state: data,
    });
  };
  const onNewCaseClick = () => {
    navigate("/rto-management-new");
  };

  const onEditCaseClick = (data) => {
    navigate("/rto-management-edit", {
      state: data,
    });
  };
  const tableRef = useRef();

  const onExport = () => {
    exportVisibleTableToExcel(tableRef.current);
  };

  // const [groupedOptions, setGroupOptions] = useState([]);

  // useEffect(() => {
  //   const groupedOptions = Object.entries(filterStatus).map(([key, value]) => ({
  //     label: key !== "null" ? key : "",
  //     options: value.map((ele) => ({
  //       value: ele.id,
  //       label: ele.name,
  //     })),
  //   }));

  //   setGroupOptions(groupedOptions);
  // }, [filterStatus]);

  return (
    <div className={style["container"]}>
      <PrimaryHeading
        title={"RTO Management"}
        fontSize={"1.75rem"}
        fontWeight={600}
        // count={totalCount}
      />
      <div className={style["table-container"]}>
        <div className={style["table-action-container"]}>
          <div className={style["input-field"]}>
            <img src={R.ic_search} alt={"Search Icon"} />
            <input
              type={"text"}
              placeholder={"Case No/Veh No"}
              // onChange={(e) => {
              //   setSearch(e?.target?.value);
              // }}
              id={"search"}
              onChange={(e) => debouncedChangeHandler(e)}
            />
          </div>

          <div className={style["input-field"]}>
            <input
              type={"date"}
              name="setStartDate"
              value={start_date}
              ref={fromDateInputRef}
              onChange={(e) => {
                setStartDate(e.target.value);
                handelPagination();
              }}
              onClick={() => fromDateInputRef.current.showPicker()}
              placeholder="Start Date"
            />
          </div>
          <div className={style["input-field"]}>
            <input
              type={"date"}
              name="setEndDate"
              value={end_date}
              ref={toDateInputRef}
              onChange={(e) => {
                setEndDate(e.target.value);
                handelPagination();
              }}
              onClick={() => toDateInputRef.current.showPicker()}
              placeholder="End Date"
              min={moment().format("YYYY-MM-DD")}
            />
          </div>

          <div className={style["input-field"]}>
            <Select
              onChange={(e) => {
                const newTaskIds = e.map(({ value }) => value);
                setStatus([...newTaskIds]);
                handelPagination();
              }}
              options={Object.entries(filterStatus).flatMap(([key, value]) =>
                value.map((ele) => ({
                  value: ele.id,
                  label: key !== "null" ? `${key} - ${ele.name}` : ele.name,
                }))
              )}
              isMulti={true}
              styles={{
                control: (base) => ({
                  ...base,
                  border: "none !important",
                  boxShadow: "none",
                  background: "transparent",
                }),
                container: (base) => ({ ...base, flex: 1, border: "none" }),
                valueContainer: (base) => ({
                  ...base,
                  flex: 1,
                  maxWidth: "120px",
                }),
                indicatorSeparator: (base) => ({ ...base, display: "none" }),
              }}
              placeholder={"Status"}
              isClearable={false}
            />
          </div>
          <div className={style["input-field"]}>
            <Select
              onChange={(e) => {
                const newTaskIds = e.map(({ value }) => value);
                setTaskId([...newTaskIds]);
                handelPagination();
              }}
              options={filterTask?.map((ele) => ({
                value: ele.id,
                label: ele.name,
              }))}
              isMulti={true}
              styles={{
                control: (base) => ({
                  ...base,
                  border: "none !important",
                  boxShadow: "none",
                  background: "transparent",
                }),
                container: (base) => ({ ...base, flex: 1, border: "none" }),
                valueContainer: (base) => ({
                  ...base,
                  flex: 1,
                  maxWidth: "120px",
                }),
                indicatorSeparator: (base) => ({ ...base, display: "none" }),
              }}
              placeholder={"Task"}
              isClearable={false}
            />
          </div>
          <div
            className={style["action-buttons-wrapper"]}
            style={{
              backgroundColor: "transparent",
            }}
          >
            <PrimaryButton
              containerStyle={style["excel-button-container-style"]}
              leadingIcon={R.ic_excel}
              name={"Export"}
              fontSize={"1rem"}
              onClick={onExport}
            />
          </div>
          <div className={style["action-buttons-wrapper"]}>
            <button
              onClick={onNewCaseClick}
              className={style["primary-action-button"]}
            >
              <img src={R.ic_add_white} alt="ic_add" /> New Case
            </button>
          </div>
        </div>
        <div
          // style={{ overflowX: "auto" }}
          className="table-responsive-md table-responsive-sm"
        >
          {loading ? (
            <LoadingModal />
          ) : (
            <table
              style={{
                overflowX: "auto",
              }}
              ref={tableRef}
              className="table table-border table-hover"
            >
              <thead>
                {table?.getHeaderGroups()?.map((headerGroup) => {
                  return (
                    <tr key={headerGroup.id}>
                      {headerGroup?.headers?.map((header) => {
                        const headerDef = header.column.columnDef;
                        return (
                          <th
                            style={{
                              overflowX: "auto",
                            }}
                            className={
                              headerDef.exportAllowed
                                ? "exportable-heading"
                                : ""
                            }
                            colSpan={header.colSpan}
                            key={header.id}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  headerDef.header,
                                  header.getContext()
                                )}
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
              </thead>

              <tbody>
                {table?.getRowModel()?.rows?.map((row) => {
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells()?.map((cell) => {
                        if (cell.column.columnDef.accessorKey === "status_name")
                          return (
                            <td key={cell.id}>
                              <Status value={cell.getValue()} />
                            </td>
                          );
                        else if (
                          cell.column.columnDef.accessorKey === "action"
                        ) {
                          const data = row?.original;
                          return (
                            <td key={cell.id}>
                              <Action
                                onViewClick={() => onViewCaseClick(data)}
                                onEditClick={() => onEditCaseClick(data)}
                                status={data?.status_name?.name}
                              />
                            </td>
                          );
                        } else
                          return (
                            <td key={cell.id}>
                              <span
                                className={"exportable-cell"}
                                data-tooltip-id={`tooltip-${cell.id}`}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </span>
                              <ReactTooltip
                                id={"tooltip-" + cell.id}
                                place="top"
                                variant="dark"
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </ReactTooltip>
                            </td>
                          );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <div className={style["table-pagination-container"]}>
        <p className={style["items-count"]}>
          {table?.getRowModel()?.rows.length?.toLocaleString()} of{" "}
          {table?.getRowCount()?.toLocaleString()}
        </p>
        <div className={style["pagination-action-wrapper"]}>
          <button
            onClick={() => {
              if (table.getCanPreviousPage()) {
                setPagination((prev) => ({
                  ...prev,
                  pageIndex: prev.pageIndex - 1,
                }));
              }
            }}
            disabled={!table.getCanPreviousPage()}
          >
            Back
          </button>

          <p>
            Page {pagination.pageIndex + 1} of{" "}
            {Math.ceil(total / pagination?.pageSize)}
          </p>
          <button
            onClick={() => {
              if (
                table.getCanNextPage() ||
                total > rtoCasesRequestList.length
              ) {
                setPagination((prev) => ({
                  ...prev,
                  pageIndex: prev.pageIndex + 1,
                }));
              }
            }}
            disabled={total == rtoCasesRequestList.length}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

const Status = ({ value }) => {
  let backgroundColor;
  switch (value) {
    case "Active":
      backgroundColor = "#E6FFD9";
      break;
    case "Inactive":
      backgroundColor = "#FFD700";
      break;
    case "Draft":
      backgroundColor = "#D3D3D3";
      break;
    case "Blacklist":
      backgroundColor = "#FF0000";
      break;
  }

  const style = {
    backgroundColor: backgroundColor,
    padding: "0px 10px",
    borderRadius: "20px",
    color: "black",
  };

  return (
    <p style={style} className={`${style["status"]} exportable-cell`}>
      {value}
    </p>
  );
};

const Action = ({ onViewClick, onEditClick, status }) => {
  return (
    <div className={style["action-container"]}>
      <img
        src={R.ic_eye_open_filled}
        alt="ic_eye_open_filled"
        onClick={onViewClick}
      />
      {status != "Completed" && status !== "Cancelled" && (
        <img src={R.ic_edit} alt="ic_edit" onClick={onEditClick} />
      )}
    </div>
  );
};

export default RtoCases;
