import { useEffect, useState, useRef, forwardRef } from "react";
import { R } from "../../constants/resources";
import style from "./style.module.scss";
import useBreakpoints from "../CustomHooks/useBreakpoints";

export const InputField = forwardRef(
  (
    {
      label,
      hint,
      name,
      value,
      defaultValue,
      renderRow,
      onChange,
      onBlur,
      onFocus,
      className,
      required = false,
      type = "text",
      leadingIcon,
      trailingIcon,
      disabled,
      error,
      innerClassName,
      length,
      items,
      onSelect,
      onQueryChange,
      selectInputStyle,
      orientation = "row",
      total,
      setPage,
      itemlength,
      children,
      invisible = false,
      min,
      dropdownHeight,
      noBorder = false,
      decreasedWidth = false,
      autoComplete = "off",
      multiple = false,
      step,
      dealerLabelType = false,
    },
    ref
  ) => {
    const inputRef = useRef(null);
    const combinedRef = ref || inputRef;
    const [shouldShowPass, setShouldShowPass] = useState(false);
    const [shouldShowDropdown, setShouldShowDropdown] = useState(false);
    const [query, setQuery] = useState("");

    useEffect(() => {
      if (type === "search-dropdown") onQueryChange(query);
    }, [query]);
    useEffect(() => {
      if (type === "search-dropdown" && value) {
        const item = items.find((it) => it.value === value);
        if (item)
          dealerLabelType
            ? setQuery(item?.label + " | " + item?.dealercode)
            : setQuery(item?.label);
      } else if (type === "search-dropdown" && value === null) {
        setQuery("");
      }
    }, [value, items]);

    useEffect(() => {
      const handleWheel = (e) => {
        if (document.activeElement === inputRef.current) {
          e.preventDefault();
        }
      };

      const inputElement = inputRef.current;
      if (inputElement && type === "number") {
        inputElement.addEventListener("wheel", handleWheel);
      }

      return () => {
        if (inputElement && type === "number") {
          inputElement.removeEventListener("wheel", handleWheel);
        }
      };
    }, [type]);

    const { isXs, isSm } = useBreakpoints();

    let defaultContainerStyle = {};
    let defaultInputFieldContainerStyle = {};
    let defaultLabelStyle = {};

    if (orientation === "column" || isXs || isSm) {
      defaultContainerStyle = { flexDirection: "column" };
      defaultInputFieldContainerStyle = { width: "100%" };
      defaultLabelStyle = { width: "100%" };
    }

    if (invisible) {
      defaultContainerStyle = {
        ...defaultContainerStyle,
        opacity: "0",
        height: "0.1px",
      };
    }

    if (decreasedWidth) {
      defaultInputFieldContainerStyle = {
        minWidth: "auto",
        width: "144px",
        flex: "initial",
      };
      defaultLabelStyle = {
        minWidth: "100px",
        width: "auto",
        fontSize: "12px",
      };
    }

    const handleDropdownBlur = () => {
      setTimeout(() => setShouldShowDropdown(false), 200);
    };

    const handleSelectChange = (selectedValue) => {
      let newValue;

      if (multiple) {
        newValue = value.includes(selectedValue)
          ? value.filter((v) => v !== selectedValue)
          : [...value, selectedValue];
      } else {
        newValue = selectedValue;
      }

      onChange(newValue);
    };

    return (
      <div
        style={defaultContainerStyle}
        className={[style["container"], decreasedWidth ? style["decreased-width"] : "", className]
          ?.filter(Boolean)
          ?.join(" ")}
        // className={[style["container"], className]?.filter(Boolean)?.join(" ")}
        // className={[style["container"], className].join(" ")}
      >
        {label && (
          <p style={defaultLabelStyle} className={style["label"]}>
            {label}
            {required && <span>*</span>}
          </p>
        )}
        <div
          style={defaultInputFieldContainerStyle}
          className={[
            style["input-field-container"],
            decreasedWidth && style["decreased-width"],
            innerClassName,
          ]
            .filter(Boolean)
            .join(" ")}
        >
          {type !== "textarea" && !children && (
            <div
              className={[
                style["input-field"],
                error && style["error"],
                noBorder && style["no-border"],
                decreasedWidth && style["decreased-width"],
              ]
                ?.filter(Boolean)
                ?.join(" ")}
            >
              {leadingIcon && <img src={leadingIcon} alt="leadingIcon" />}
              {type === "password" ? (
                <>
                  <input
                    ref={inputRef}
                    name={name}
                    value={value}
                    autoComplete={autoComplete}
                    onChange={(e) => onChange(e.target.value)}
                    onBlur={onBlur}
                    type={shouldShowPass ? "text" : "password"}
                    placeholder={hint}
                    disabled={disabled}
                    maxLength={length}
                  />
                </>
              ) : type === "select" ? (
                <>
                  <select
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    autoComplete={autoComplete}
                    disabled={disabled}
                    className={selectInputStyle}
                    style={!value ? { color: "#918f8f" } : { color: "#000" }}
                  >
                    <option value="">{hint}</option>
                    {items?.map((item, index) => {
                      return (
                        <option
                          key={index}
                          value={item?.value}
                          name={item?.label}
                          disabled={item?.disabled}
                        >
                          {item?.label}
                          {item?.subLabel ? item?.sublabel : ""}
                        </option>
                      );
                    })}
                    {total > itemlength && (
                      <p onClick={() => setPage((page) => page + 1)}>Load More</p>
                    )}
                  </select>
                </>
              ) : type === "multi-select" ? (
                <>
                  <select
                    multiple={multiple}
                    value={value}
                    onChange={(e) => handleSelectChange(e.target.value)}
                    onBlur={onBlur}
                    autoComplete={autoComplete}
                    disabled={disabled}
                    className={selectInputStyle}
                    style={!value ? { color: "#918f8f" } : { color: "#000" }}
                  >
                    <option value="">{hint}</option>
                    {items?.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item?.label}
                        {item?.subLabel ? item?.sublabel : ""}
                      </option>
                    ))}
                    {total > itemlength && (
                      <p onClick={() => setPage((page) => page + 1)}>Load More</p>
                    )}
                  </select>
                  {multiple && (
                    <div className={style["selected-tags"]}>
                      {value?.map((val, index) => {
                        const item = items.find((it) => it.value === val);
                        return (
                          <span key={index} className={style["selected-tag"]}>
                            {item?.label}
                            <button
                              onClick={() => handleSelectChange(val)}
                              className={style["remove-tag-button"]}
                            >
                              &times;
                            </button>
                          </span>
                        );
                      })}
                    </div>
                  )}
                </>
              ) : type === "search-dropdown" ? (
                <div style={{ position: "relative", flex: 1, display: "flex" }}>
                  <input
                    ref={inputRef}
                    value={query}
                    placeholder={hint}
                    className={selectInputStyle}
                    onChange={(e) => {
                      setShouldShowDropdown(true);
                      setQuery(e.target.value);
                      if (value || value === null) onChange("");
                    }}
                    onBlur={handleDropdownBlur}
                    onFocus={() => {
                      setShouldShowDropdown(true);
                      onFocus && onFocus();
                    }}
                    disabled={disabled}
                    onClick={() => setShouldShowDropdown(true)}
                  />
                  {items?.length > 0 && shouldShowDropdown && (
                    <div
                      style={{
                        height: dropdownHeight ? dropdownHeight : "auto",
                      }}
                      className={style["search-select-dropdown-wrapper"]}
                    >
                      {items?.map((item, index) => {
                        if (renderRow)
                          return (
                            <div
                              key={index}
                              onMouseDown={() => {
                                if (!item.disabled) {
                                  onChange(item.value);
                                  setShouldShowDropdown(false);
                                }
                              }}
                              className={`${style["custom-dropdown-option"]} ${
                                item.disabled ? style["disabled-option"] : ""
                              }`}
                              style={{
                                cursor: item.disabled ? "not-allowed" : "pointer",
                                opacity: item.disabled ? 0.6 : 1,
                              }}
                            >
                              {renderRow(item)}
                            </div>
                          );
                        else
                          return (
                            <p
                              key={index}
                              onMouseDown={() => {
                                if (!item.disabled) {
                                  onChange(item.value);
                                  setShouldShowDropdown(false);
                                }
                              }}
                              className={`${style["default-dropdown-option"]} ${
                                item.disabled ? style["disabled-option"] : ""
                              }`}
                              style={{
                                cursor: item.disabled ? "not-allowed" : "pointer",
                                opacity: item.disabled ? 0.6 : 1,
                              }}
                            >
                              {item?.label}
                            </p>
                          );
                      })}
                    </div>
                  )}
                </div>
              ) : type === "number" ? (
                <input
                  ref={inputRef}
                  name={name}
                  value={value || ""}
                  autoComplete={autoComplete}
                  onChange={(e) => onChange(e.target.value)}
                  onBlur={onBlur}
                  type={"number"}
                  placeholder={hint}
                  disabled={disabled}
                  maxLength={length}
                  step={step}
                  min={min}
                />
              ) : type === "mobile-number" ? (
                <input
                  ref={inputRef}
                  name={name}
                  value={value}
                  autoComplete={autoComplete}
                  onChange={(e) => {
                    // Allow only up to 10 digits in the mobile number
                    const inputValue = e.target.value;
                    if (inputValue.length <= 10 && /^[0-9]*$/.test(inputValue)) {
                      onChange(inputValue);
                    }
                  }}
                  onBlur={onBlur}
                  type={"tel"}
                  placeholder={hint}
                  maxLength={10}
                  inputMode="numeric" // Mobile-friendly numeric input mode
                  pattern="[0-9]{10}" // Pattern to ensure exactly 10 digits
                  disabled={disabled}
                />
              ) : type === "checkbox" ? (
                <input
                  name={name}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  type={"checkbox"}
                />
              ) : (
                <input
                  ref={inputRef}
                  name={name}
                  autoComplete={autoComplete}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  onBlur={onBlur}
                  type={type}
                  placeholder={hint}
                  disabled={disabled}
                  maxLength={length}
                  min={type === "date" ? min : ""}
                />
              )}
              {trailingIcon && (
                <img className={style["trailing-icon"]} src={trailingIcon} alt="trailingIcon" />
              )}
              {type === "password" && !trailingIcon && (
                <img
                  onClick={() => setShouldShowPass(!shouldShowPass)}
                  className={style["trailing-icon"]}
                  src={shouldShowPass ? R.ic_password_view : R.ic_password_view_off}
                  alt="trailingIcon"
                />
              )}
            </div>
          )}
          {type === "textarea" && !children && (
            <div className={`${style["input-field"]} ${error && style["error"]}`}>
              <textarea ref={inputRef} autoComplete={autoComplete} placeholder={hint}></textarea>
            </div>
          )}
          {children ? children : ""}
          {error && <p className={style["error-text"]}>{error}</p>}
        </div>
      </div>
    );
  }
);
