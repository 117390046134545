import * as Yup from "yup";
import { Regex } from "./regex";

const validationSchema = {
  // Auth Validation
  login: Yup.object().shape({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string()
      .matches(
        Regex.PASSWORD,
        "Password must be 8 to 16 characters long and includes at least one uppercase letter, one lowercase letter, one number, and one special character"
      )
      .required(
        "Password must be 8 to 16 characters long and includes at least one uppercase letter, one lowercase letter, one number, and one special character"
      ),
  }),

  forgotPassword: Yup.object().shape({
    email: Yup.string().email("Email is invalid").required("Email is required"),
  }),

  resetPassword: Yup.object().shape({
    password: Yup.string()
      .matches(
        Regex.PASSWORD,
        "Password must be 8 to 16 characters long and includes at least one uppercase letter, one lowercase letter, one number, and one special character"
      )
      .required(
        "Password must be 8 to 16 characters long and includes at least one uppercase letter, one lowercase letter, one number, and one special character"
      ),
    confirmPassword: Yup.string()
      .matches(
        Regex.PASSWORD,
        "Password must be 8 to 16 characters long and includes at least one uppercase letter, one lowercase letter, one number, and one special character"
      )
      .test(
        "passwords-match",
        "New Password must be equal to Confirm Password",
        function (value) {
          return this.parent.password === value;
        }
      )
      .required(
        "Password must be 8 to 16 characters long and includes at least one uppercase letter, one lowercase letter, one number, and one special character"
      ),
  }),

  // User Validation
  addUser: Yup.object().shape({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string()
      .matches(
        Regex.PASSWORD,
        `Password must be 8 to 16 characters 
        long and includes at least one uppercase letter, 
        one lowercase letter, one number, and one special character`
      )
      .required(
        `Password must be 8 to 16 characters long and includes 
          at least one uppercase letter, one lowercase letter, 
          one number, and one special character`
      ),
    firstname: Yup.string()
      .trim()
      .matches(Regex.NAME, "Name contains only alphabates")
      .max(20, "Name contains 20 characters only")
      .required("Name is required"),
    phone: Yup.string()
      .trim()
      .matches(Regex.MOBILE, "Phone number must be valid upto 10 digits.")
      .required("Phone number is required"),
    roleId: Yup.string().required("Role is required"),
    // reportingManager: Yup.number().required("Reporting Manager is required"),
  }),

  // Role Validation
  addRole: Yup.object().shape({
    name: Yup.string()
      .trim()
      .matches(Regex.NAME, "Role name contains only alphabates")
      .max(20, "Role name contains 20 characters only")
      .required("Role name is required"),
    status: Yup.string(),
    // permissions: Yup.array()
    //   .of(
    //     Yup.object().shape({
    //       Type: Yup.string(),
    //       checked: Yup.boolean(),
    //     })
    //   )
    //   .required("required"),
    permissions: "",
  }),

  addKycDetail: Yup.object().shape({
    isGSTNotAvailable: Yup.boolean().default(false),
    pan_number: Yup.string()
      .trim()
      .matches(Regex.PAN, "PAN Number is invalid")
      .required("PAN number is required"),
    gst_number: Yup.string()
      .trim()
      .when("isGSTNotAvailable", {
        is: false,
        then: () =>
          Yup.string()
            .required("GSTIN is required")
            .matches(Regex.GSTIN, "Invalid GSTIN number"),
        otherwise: () => Yup.string(),
      }),
    aadhar_number: Yup.string()
      .trim()
      .matches(Regex.AADHAAR, "Invalid Aadhaar number"),
    // .required("Aadhaar number is required"),
    // non_gst_document: Yup.string().when("isGSTNotAvailable", {
    //   is: true,
    //   then: () => Yup.string().required("Non-GSTIN document is required"),
    //   otherwise: () => Yup.string(),
    // }),
    non_gst_document: Yup.string(),
    address_proof_document: Yup.string(),
    visiting_card_document: Yup.string(),
  }),

  addBankDetails: Yup.object().shape({
    ifsc: Yup.string()
      .trim()
      .matches(Regex.IFSC, "IFSC is invalid")
      .required("IFSC is required"),
    account_name: Yup.string()
      .trim()
      .matches(Regex.DEALERSHIP_NAME, "Account Name contains only alphabates")
      .required("Account Name is required"),
    account_number: Yup.string()
      .trim()
      .matches(Regex.ACCOUNT_NUMBER, "Account number must be in 9-20 digits")
      .required("Account number is required"),
    bankName: Yup.string().required("Bank name is required"),
    branchAddress: Yup.string().required("Branch address is required"),
  }),

  addDealerQrCode: Yup.object().shape({
    ifsc: Yup.string()
      .trim()
      .matches(Regex.IFSC, "IFSC is invalid")
      .required("IFSC is required"),
    bank_account_name: Yup.string()
      .trim()
      .matches(Regex.DEALERSHIP_NAME, "Account Name contains only alphabates")
      .required("Account Name is required"),
    bank_ac_number: Yup.string()
      .trim()
      .matches(Regex.ACCOUNT_NUMBER, "Account number must be in 9-20 digits")
      .required("Account number is required"),
    bank_name: Yup.string().required("Bank name is required"),
    branch_address: Yup.string().required("Branch address is required"),
    qr_link: Yup.string().required("QR Code is required"),
    bank_acc_type: Yup.string().required("Bank Account Type is required"),
  }),

  addBasicDetails: Yup.object().shape({
    dealername: Yup.string()
      .trim()
      .matches(
        /^\S.*\S$|^\S$/,
        "Dealer Name cannot have leading or trailing spaces"
      )
      .matches(Regex.DEALER_NAME, "Name contains only alphabates")
      .max(30, "Name contains 30 characters only")
      .required("Dealer Name is required"),
    dealership_name: Yup.string()
      .trim()
      .matches(
        /^\S.*\S$|^\S$/,
        "Dealership Name cannot have leading or trailing spaces"
      )
      .matches(Regex.DEALERSHIP_NAME, "Name contains only alphabates")
      .max(30, "Name contains 30 characters only")
      .required("Dealership Name & Code is required"),
    dealeremail: Yup.string().email("Email is invalid"),
    // .required("Email is required"),
    dealerMobile: Yup.string()
      .trim()
      .matches(Regex.MOBILE, "Phone number must be valid upto 10 digits.")
      .required("Phone Number is required"),
    status: Yup.string(),
    regAddress: Yup.object().shape({
      // address: Yup.string().required("Registered address is required"),
      address: Yup.string()
        .required("Registered Address is required")
        .matches(Regex.ADDRESS, "Registered Address is not valid"),
      pincode: Yup.string()
        .trim()
        .matches(Regex.PINCODE, "Invalid pincode")
        .required("Registered Pincode is required"),
      city_name: Yup.string().required("City Name is required"),
      state_name: Yup.string().required("State Name is required"),
    }),

    dealerAdd: Yup.object().shape({
      address: Yup.string(),
      // .required("Office Address is required"),
      pincode: Yup.string(),
      // .required("Office Pincode is required"),
      state: Yup.string(),
      city: Yup.string(),
      latitude: Yup.string().matches(
        /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)/,
        "Invalid latitude"
      ),
      longitude: Yup.string().matches(
        /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)/,
        "Invalid longitude"
      ),
    }),
  }),

  // addCreditDetails: Yup.object().shape({
  //   creditLimit: Yup.array().of(
  //     Yup.object().shape({
  //       service_id: Yup.number().integer().required("Service id is required"),
  //       limit: Yup.number().integer().required("Credit limit is required"),
  //       // .test("empty-check", "Limit cannot be 0", (value) => value !== 0),
  //     })
  //   ),
  //   services: Yup.array().of(
  //     Yup.object().shape({
  //       service_id: Yup.number().integer().required(),
  //       service_charge: Yup.number()
  //         .integer()
  //         .required("Service charge is required"),
  //       // .test("empty-check", "Charge cannot be 0", (value) => value !== 0),
  //     })
  //   ),
  // }),

  addCreditDetails: Yup.object().shape({
    financeData: Yup.array().of(
      Yup.object().shape({
        service_id: Yup.number(),
        price_applicable: Yup.boolean(),
        limit_applicable: Yup.boolean(),
        service_charge: Yup.number(),
        credit_limit: Yup.number(),
      })
    ),
    walletAmount: Yup.number(),
    coinAmount: Yup.number(),
  }),

  addServiceHistory: Yup.object().shape({
    dealer_id: Yup.number().required("Dealer Name is required"),
    make_id: Yup.number().required("Vehicle Make is required"),
    model_id: Yup.number().required("Vehicle Model is required"),
    engine_no: Yup.string(),
    chassis_no: Yup.string(),
    rc_no: Yup.string()
      .matches(Regex.VEHICLE_NUMBER, "Invalid Vehicle Number")
      .required("Vehicle Number is required"),
    amount: Yup.number()
      .typeError("Fee Amount must be a number")
      .required("Fee Amount is required")
      .test(
        "is-number",
        "Fee Amount must only contain digits or up to two decimal places",
        (value) => /^\d+(\.\d{1,2})?$/.test(value.toString())
      ),
    agreed_amount: Yup.number()
      .typeError("Agreed Amount must be a number")
      .required("Agreed Amount is required")
      .test(
        "is-number",
        "Agreed Amount must only contain digits or up to two decimal places",
        (value) => /^\d+(\.\d{1,2})?$/.test(value.toString())
      ),
    gst_amount: Yup.number().required("GSTIN is required"),
    consultancy: Yup.number().required("Consultancy is required"),
    base_consultancy: Yup.number().required("Base Consultancy is required"),
    total_amount: Yup.number().required("Total Amount is required"),
  }),

  editServiceHistory: Yup.object().shape({
    make_id: Yup.number().required("Vehicle Make is required"),
    model_id: Yup.number().required("Vehicle Model is required"),
    rc_no: Yup.string().matches(Regex.VEHICLE_NUMBER, "Invalid Vehicle Number"),
    status: Yup.number(),
    sh_report_link: Yup.string(),
    admin_assigned: Yup.string().nullable(true),
    completion_date: Yup.string().nullable(true),
    amount: Yup.number()
      .typeError("Fee Amount must be a number")
      .required("Fee Amount is required")
      .test(
        "is-number",
        "Fee Amount must only contain digits or up to two decimal places",
        (value) => /^\d+(\.\d{1,2})?$/.test(value.toString())
      ),
    agreed_amount: Yup.number()
      .typeError("Agreed Amount must be a number")
      .required("Agreed Amount is required")
      .test(
        "is-number",
        "Agreed Amount must only contain digits or up to two decimal places",
        (value) => /^\d+(\.\d{1,2})?$/.test(value.toString())
      ),
    gst_amount: Yup.number().required("GSTIN is required"),
    consultancy: Yup.number().required("Consultancy is required"),
    base_consultancy: Yup.number().required("Base Consultancy is required"),
    total_amount: Yup.number().required("Total Amount is required"),
  }),

  addPickupRequest: Yup.object().shape({
    dealer_id: Yup.number().required("Dealer Name is required"),
    ds_service_id: Yup.number().required("Service Type is required"),
    estCasesNo: Yup.string().matches(
      /^\d+$/,
      "Est Cases no. should be in integer"
    ),
    //   .required("Estimated Cases no. is required"),
    remarks: Yup.string().trim(),
    pocName: Yup.string()
      .trim()
      .matches(Regex.DEALER_NAME, "Name contains only alphabates")
      .max(30, "Name contains 30 characters only")
      .required("POC Name is required"),
    pocMobile: Yup.string()
      .trim()
      .matches(Regex.MOBILE, "Phone number must be valid upto 10 digits.")
      .required("POC Mobile is required"),
    schDateTime: Yup.string().required("Schedule Date is required"),
    schSlotId: Yup.string().required("Schedule Slot is required"),
    regAddress: Yup.object().shape({
      address: Yup.string().required("Address is required"),
      pincode: Yup.string(),
      state: Yup.string(),
      city: Yup.string(),
    }),
    admin_assigned: Yup.number().required("Admin assignee is required"),
    execAssigned: Yup.number().required("Executive assignee is required"),
    // estCarNo: Yup.string().when("ds_service_id", {
    //   is: (val) => val !== 2,
    //   then: () => Yup.string(),
    //   otherwise: () =>
    //     Yup.string()
    //       .matches(/^\d+$/, "Est Car No. should be in integer")
    //       .required("Est Car No. is required"),
    // }),
    // amount: Yup.number()
    //   .typeError("Fee Amount must be a number")
    //   .required("Fee Amount is required")
    //   .test(
    //     "is-number",
    //     "Fee Amount must only contain digits or up to two decimal places",
    //     (value) => /^\d+(\.\d{1,2})?$/.test(value.toString())
    //   ),
    // agreed_amount: Yup.number()
    //   .typeError("Agreed Amount must be a number")
    //   .required("Agreed Amount is required")
    //   .test(
    //     "is-number",
    //     "Agreed Amount must only contain digits or up to two decimal places",
    //     (value) => /^\d+(\.\d{1,2})?$/.test(value.toString())
    //   ),
    // gst_amount: Yup.number().required("GSTIN is required"),
    // consultancy: Yup.number().required("Consultancy is required"),
    // base_consultancy: Yup.number().required("Base Consultancy is required"),
    // total_amount: Yup.number().required("Total Amount is required"),
  }),

  editPickupRequest: Yup.object().shape({
    status: Yup.number().required("Status is required"),
    estCasesNo: Yup.string().matches(
      /^\d+$/,
      "Est Cases no. should be in integer"
    ),
    remarks: Yup.string().trim(),
    pocName: Yup.string()
      .trim()
      .matches(Regex.NAME, "Name conatins only alphabates")
      .required("Poc Name is required"),
    pocMobile: Yup.string()
      .trim()
      .matches(Regex.MOBILE, "Poc Mobile number should be valid"),
    prefDateTime: Yup.string().when("status", {
      is: (val) => val == 2 || val == 3,
      then: () => Yup.string().required("Preferred Date is required"),
      otherwise: () => Yup.string().nullable(true),
    }),
    prefSlotId: Yup.string().when("status", {
      is: (val) => val == 2 || val == 3,
      then: () => Yup.string().required("Preferred Slot is required"),
      otherwise: () => Yup.string().nullable(true),
    }),
    // schDateTime: Yup.string().when("status", {
    //   is: (val) => val === 2 || val === 3,
    //   then: () => Yup.string().required("Schedule date is required"),
    //   otherwise: () => Yup.string().nullable(true),
    // }),
    // schSlotId: Yup.string().when("status", {
    //   is: (val) => val == 2 || val == 3,
    //   then: () => Yup.string().required("Schedule slot is required"),
    //   otherwise: () => Yup.string().nullable(true),
    // }),
    schDateTime: Yup.string().required("Schedule Date is required"),
    schSlotId: Yup.string().required("Schedule Slot is required"),
    admin_assigned: Yup.number().required("Admin Assign is required"),
    execAssigned: Yup.number().required("Executive Assign is required"),
    regAddress: Yup.object().shape({
      address: Yup.string().trim(),
      pincode: Yup.string().trim(),
      state: Yup.string().trim(),
      city: Yup.string().trim(),
    }),
    // amount: Yup.number()
    //   .typeError("Fee Amount must be a number")
    //   .required("Fee Amount is required")
    //   .test(
    //     "is-number",
    //     "Fee Amount must only contain digits or up to two decimal places",
    //     (value) => /^\d+(\.\d{1,2})?$/.test(value.toString())
    //   ),
    // agreed_amount: Yup.number()
    //   .typeError("Agreed Amount must be a number")
    //   .required("Agreed Amount is required")
    //   .test(
    //     "is-number",
    //     "Agreed Amount must only contain digits or up to two decimal places",
    //     (value) => /^\d+(\.\d{1,2})?$/.test(value.toString())
    //   ),
    // gst_amount: Yup.number().required("GSTIN is required"),
    // consultancy: Yup.number().required("Consultancy is required"),
    // base_consultancy: Yup.number().required("Base Consultancy is required"),
    // total_amount: Yup.number().required("Total Amount is required"),
  }),

  addRtoCases: Yup.object().shape({
    rc_no: Yup.string()
      .matches(Regex.VEHICLE_NUMBER, "Vehicle Number should be valid")
      .required("Vehicle Number is required"),
    auth_from: Yup.number().required("Authority From is required"),
    auth_to: Yup.number().required("Authority To is required"),
    buyer_name: Yup.string()
      .required("Buyer Name is required"),
    buyer_phone: Yup.string()
      .required("Buyer Phone is required"),
    seller_name: Yup.string()
      .required("Buyer Name is required"),

    seller_phone: Yup.string()
      .required("Buyer Phone is required"),

    remarks: Yup.string().required("Remarks is required"),
  }),


  editRtoCases: Yup.object().shape({
    rc_no: Yup.string()
      .matches(Regex.VEHICLE_NUMBER, "Vehicle Number should be valid")
      .required("Vehicle Number is required"),
    dealer_id: Yup.number().required("Dealer Name is required"),
    auth_from: Yup.number().required("Authority From is required"),
    auth_to: Yup.number().required("Authority To is required"),
    buyer_name: Yup.string()
      .trim()
      .matches(Regex.NAME, "Name contains only alphabates")
      .required("Buyer Name is required"),
    buyer_phone: Yup.string()
      .trim()
      .matches(Regex.MOBILE, "Mobile number should be valid")
      .required("Buyer Phone is required"),
    task_id: Yup.number().required("Task is required"),
    seller_name: Yup.string()
      .trim()
      .matches(Regex.NAME, "Name contains only alphabates")
      .required("Buyer Name is required"),

    seller_phone: Yup.string()
      .trim()
      .matches(Regex.MOBILE, "Mobile number should be valid")
      .required("Buyer Phone is required"),

    remarks: Yup.string().required("Remarks is required"),
  }),


  createChPayRequest: Yup.object().shape({
    dealer_id: Yup.number().required("Dealer Name is required"),
    rc_no: Yup.string()
      .matches(Regex.VEHICLE_NUMBER, "Invalid Vehicle Number")
      .required("Vehicle Number is required"),
  }),

  editChPayRequest: Yup.object().shape({
    clear_status: Yup.number().required("Consultancy Status is required"),
    admin_assigned: Yup.number().required("Admin Assignee is required"),
    cust_response: Yup.number().required("Customer Response is required"),
    call_done: Yup.number().required("Call done is required"),
  }),

  createManualChallan: Yup.object().shape({
    challanNo: Yup.string().required("Challan Number is required"),
    challanDate: Yup.string().required("Challan Date is required"),
    ownerName: Yup.string()
      .trim()
      .matches(Regex.NAME, "Owner Name contains only alphabates")
      .max(20, "Owner Name contains 20 characters only")
      .required("Owner Name is required"),

    rtoName: Yup.string().required("RTO name is required"),
    description: Yup.string().required("Description is required"),
    courtName: Yup.string().required("Court Name is required"),
    amount: Yup.number()
      .typeError("Fee Amount must be a number")
      .required("Fee Amount is required")
      .test(
        "is-number",
        "Fee Amount must only contain digits or up to two decimal places",
        (value) => /^\d+(\.\d{1,2})?$/.test(value.toString())
      ),
    agreed_amount: Yup.number()
      .typeError("Agreed Amount must be a number")
      .required("Agreed Amount is required")
      .test(
        "is-number",
        "Agreed Amount must only contain digits or up to two decimal places",
        (value) => /^\d+(\.\d{1,2})?$/.test(value.toString())
      ),
    gst_amount: Yup.number().required("GSTIN is required"),
    consultancy: Yup.number().required("Consultancy is required"),
    base_consultancy: Yup.number().required("Base Consultancy is required"),
    total_amount: Yup.number().required("Total Amount is required"),
  }),

  editManualChallan: Yup.object().shape({
    challanNo: Yup.string(),
    challanDate: Yup.string(),
    ownerName: Yup.string()
      .trim()
      .max(20, "Owner Name contains 20 characters only"),
    rtoName: Yup.string(),
    description: Yup.string(),
    courtName: Yup.string(),
    amount: Yup.number()
      .typeError("Fee Amount must be a number")
      .required("Fee Amount is required")
      .test(
        "is-number",
        "Fee Amount must only contain digits or up to two decimal places",
        (value) => /^\d+(\.\d{1,2})?$/.test(value.toString())
      ),
    agreed_amount: Yup.number()
      .typeError("Agreed Amount must be a number")
      .required("Agreed Amount is required")
      .test(
        "is-number",
        "Agreed Amount must only contain digits or up to two decimal places",
        (value) => /^\d+(\.\d{1,2})?$/.test(value.toString())
      ),
    gst_amount: Yup.number().required("GSTIN is required"),
    consultancy: Yup.number().required("Consultancy is required"),
    base_consultancy: Yup.number().required("Base Consultancy is required"),
    total_amount: Yup.number().required("Total Amount is required"),
    status: Yup.number().required("Status is required"),
  }),

  postBankTransaction: Yup.object().shape({
    dealer_id: Yup.number().required("Dealer Name is required"),
    // service_id: Yup.number().required("Servive Type is required"),
    transaction_type: Yup.number().required("Transaction Type is required"),
    coin_type: Yup.string()
      .trim()
      .when("transaction_type", {
        is: 2,
        then: (schema) => {
          return schema.required("Coin Type is required");
        },
        otherwise: (schema) => {
          return schema.notRequired();
        },
      }),
    expiry_date: Yup.string()
      .trim()
      .when("transaction_type", {
        is: 2,
        then: (schema) => {
          return schema.required("Expiry Date is required");
        },
        otherwise: (schema) => {
          return schema.notRequired();
        },
      }),
    // expiry_date: Yup.string()
    //   .trim()
    //   .when("transaction_type", {
    //     is: (val) => val === 2,
    //     then: () => Yup.string().required("Expiry Date is required"),
    //     otherwise: () => Yup.string(),
    //   }),

    amount: Yup.number().required("Amount is required"),
    utr: Yup.string()
      .trim()
      .matches(Regex.UTR, "UTR contains only alphabates & numeric only")
      .max(25, "UTR contains 25 characters only")
      .when("transaction_type", {
        is: 1,
        then: (schema) => {
          return schema.required("UTR is required");
        },
        otherwise: (schema) => {
          return schema.notRequired();
        },
      }),
    payment_mode: Yup.string().when("transaction_type", {
      is: 1,
      then: (schema) => {
        return schema.required("Transaction Type is required");
      },
      otherwise: (schema) => {
        return schema.notRequired();
      },
    }),
    bank_name: Yup.string().when("transaction_type", {
      is: 1,
      then: (schema) => {
        return schema.required("Bank name is required");
      },
      otherwise: (schema) => {
        return schema.notRequired();
      },
    }),
    bank_ifsc: Yup.string()
      .trim()
      .matches(Regex.IFSC, "IFSC is invalid")
      .when("transaction_type", {
        is: 1,
        then: (schema) => {
          return schema.required("IFSC is required");
        },
        otherwise: (schema) => {
          return schema.notRequired();
        },
      }),
    bank_account_no: Yup.string()
      .trim()
      .matches(Regex.ACCOUNT_NUMBER, "Account number must be in 9-20 digits")
      .when("transaction_type", {
        is: 1,
        then: (schema) => {
          return schema.required("Account number is required");
        },
        otherwise: (schema) => {
          return schema.notRequired();
        },
      }),
    transaction_receipt: Yup.string().nullable(true),
  }),

  addLoanBasicDetails: Yup.object().shape({
    customer_phone: Yup.string()
      .trim()
      .matches(Regex.MOBILE, "Phone number must be valid upto 10 digits.")
      .required("Applicant Phone Number is required"),
    customer_name: Yup.string()
      .trim()
      .matches(Regex.DEALER_NAME, "Name contains only alphabates")
      .max(30, "Name contains 30 characters only")
      .required("Applicant Name is required"),
    applicant_type: Yup.number().required("Applicant Type is required"),
    customer_email: Yup.string().email("Email is invalid"),
    lead_status: Yup.number().required("Lead Status is required"),
    permanent_Add: Yup.object().shape({
      address: Yup.string()
        .required("Permanent Address is required")
        .matches(Regex.ADDRESS, "Permanent Address is not valid"),
      pincode: Yup.string()
        .trim()
        .matches(Regex.PINCODE, "Invalid pincode")
        .required("Permanent Pincode is required"),
      state: Yup.string(),
      city: Yup.string(),
      // latitude: Yup.string().matches(
      //   /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)/,
      //   "Invalid latitude"
      // ),
      // longitude: Yup.string().matches(
      //   /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)/,
      //   "Invalid longitude"
      // ),
    }),
    current_Add: Yup.object().shape({
      address: Yup.string().required("Current Address is required"),
      pincode: Yup.string().required("Current Pincode is required"),
      state: Yup.string().nullable(true),
      city: Yup.string().nullable(true),
      // latitude: Yup.string().matches(
      //   /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)/,
      //   "Invalid latitude"
      // ),
      // longitude: Yup.string().matches(
      //   /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)/,
      //   "Invalid longitude"
      // ),
    }),
    dealer_id: Yup.string()
      .trim()
      .required("Dealership Name & Code is required"),
    lead_source: Yup.number().required("Lead Source is required"),
    remarks: Yup.string(),
    // pan_number: Yup.string().trim().matches(Regex.PAN, "Invalid PAN number"),
    // aadhar_number: Yup.string()
    //   .trim()
    //   .matches(Regex.AADHAAR, "Invalid Aadhaar number"),
    // nature_of_business: Yup.string().trim(),
    // current_add_document: Yup.string(),
    // itr_document: Yup.string(),
    // bank_statement: Yup.string(),
    // business_document: Yup.string(),
    // photo_document: Yup.string(),
  }),

  addLoanKYCDetails: Yup.object().shape({
    pan_number: Yup.string()
      .trim()
      .matches(Regex.PAN, "Invalid PAN number")
      .required("PAN number is required"),
    aadhar_number: Yup.string()
      .trim()
      .matches(Regex.AADHAAR, "Invalid Aadhaar number")
      .required("Aadhaar Number is required"),
    aadhar_document: Yup.object().shape({
      doc: Yup.string().required("Aadhaar Card document is required"),
      doc_1: Yup.string().nullable(true),
    }),
    pan_document: Yup.object().shape({
      doc: Yup.string().required("PAN document is required"),
    }),

    nature_of_business: Yup.string().required("Nature of Business is required"),
  }),

  addLoanKYCDocDetails: Yup.object().shape({
    pan_number: Yup.string().trim().matches(Regex.PAN, "Invalid PAN number"),
    aadhar_number: Yup.string()
      .trim()
      .matches(Regex.AADHAAR, "Invalid Aadhaar number"),
    nature_of_business: Yup.string().required("Nature of Business is required"),
    business_proof: Yup.object().shape({
      doc: Yup.string().required(
        "Business Proof (GST or MSME) document is required"
      ),
    }),
  }),

  addLoanDetailsValidationSchema: Yup.object().shape({
    loan_type: Yup.number(),
    loan_sub_type: Yup.number(),
    loan_status: Yup.number(),
    rc_no: Yup.string().matches(Regex.VEHICLE_NUMBER, "Invalid Vehicle Number"),
    make_id: Yup.number(),
    model_id: Yup.number(),
    variant_id: Yup.number(),
    reg_year: Yup.string().matches(/^\d{4}$/, "Must be exactly 4 digits"),
    rto_location: Yup.object().shape({
      address: Yup.string(),
      pincode: Yup.string(),
      state: Yup.string(),
      city: Yup.string(),
      latitude: Yup.string().matches(
        /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)/,
        "Invalid latitude"
      ),
      longitude: Yup.string().matches(
        /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)/,
        "Invalid longitude"
      ),
    }),
    loan_start: Yup.date().nullable(true),
    loan_number: Yup.string(),
    loan_amount: Yup.string(),
    loan_rc_image: Yup.string().when("loan_type", {
      is: (val) => [2, 3, 4].includes(val),
      then: () => Yup.string().required("RC Image document is required"),
      otherwise: () => Yup.string(),
    }),
    loan_booking_receipt: Yup.string().when("loan_type", {
      is: 1,
      then: () => Yup.string().required("Booking Receipt document is required"),
      otherwise: () => Yup.string(),
    }),
    // lender_data: Yup.array().of(
    //   Yup.object().shape({
    //     file_id: Yup.string().when([], {
    //       is: () => !!loanId,
    //       then: Yup.string().required('File Id is required'),
    //     }),
    //     lender_application_id: Yup.string(),
    //     lender_type: Yup.string().when([], {
    //       is: () => !!loanId,
    //       then: Yup.string().required('Lender Type is required'),
    //     }),
    //     lender_name: Yup.string().when('lender_type', {
    //       is: (lender_type) => lender_type !== 1 && lender_type !== 2 && !!loanId,
    //       then: Yup.string().required('Lender Name is required'),
    //     }),
    //     lender_id: Yup.string().when('lender_type', {
    //       is: (lender_type) => (lender_type === 1 || lender_type === 2) && !!loanId,
    //       then: Yup.string().required('Lender is required'),
    //     }),
    //     loan_amount: Yup.string().when([], {
    //       is: () => !!loanId,
    //       then: Yup.string().required('Loan Amount is required'),
    //     }),
    //     interest_type: Yup.string(),
    //     roi: Yup.string(),
    //     tenure: Yup.string(),
    //     tenure_type: Yup.string(),
    //     status: Yup.string(),
    //   })
    // ),
  }),

  addPayoutDetails: Yup.object().shape({
    loan_id: Yup.string(),
    loan_number: Yup.string(),
    loan_amount: Yup.string(),
    lender_name: Yup.string(),
    loan_payin: Yup.string(),
    payout_status: Yup.number(),
    partner_name: Yup.string(),
    partner_code: Yup.string(),
    bank_account: Yup.string(),
    ifsc_code: Yup.string(),
    partner_payout: Yup.string(),
    pnl: Yup.string(),
  }),

  addPhotoShootRequest: Yup.object().shape({
    dealer_id: Yup.number().required("Dealer Name is required"),
    photoshoot_type_id: Yup.number().required("PhotoShoot Type is required"),
    shoot_count: Yup.number()
      .min(1, "Count of Shoots must be at least 1")
      .required("Count of Shoots is required"),
    location: Yup.object().shape({
      address: Yup.string(),
      pincode: Yup.string(),
      state: Yup.string(),
      city: Yup.string(),
      latitude: Yup.string().matches(
        /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)/,
        "Invalid latitude"
      ),
      longitude: Yup.string().matches(
        /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)/,
        "Invalid longitude"
      ),
    }),
    schDateTime: Yup.string().required("Schedule Date is required"),
    schSlotId: Yup.string().required("Schedule Slot is required"),
    is_price_added: Yup.boolean(),
    // gst_type: Yup.string().trim().required("Gst Type is required"),
    gst_type: Yup.string().when("is_price_added", {
      is: true,
      then: () =>
        Yup.string()
          .required("Gst Type is required")
          .oneOf(
            ["GST_Included", "GST_Excluded", "GST_Not_Applicable"],
            "Invalid Gst Type"
          ),
      otherwise: () => Yup.string().notRequired(),
    }),
    quantity: Yup.number().when("is_price_added", {
      is: true,
      then: () => Yup.number().required("Quantity is required"),
      otherwise: () => Yup.number().notRequired(),
    }),
    agreed_amount: Yup.number()
      .typeError("Agreed Amount must be a number")
      .when("is_price_added", {
        is: true,
        then: () =>
          Yup.number()
            .required("Agreed Amount is required")
            .test(
              "is-number",
              "Agreed Amount must only contain digits or up to two decimal places",
              (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        otherwise: () => Yup.number().notRequired(),
      }),
    total_amount: Yup.number().when("is_price_added", {
      is: true,
      then: () => Yup.number().required("Net Price is required"),
      otherwise: () => Yup.number().notRequired(),
    }),
    rc_no_arr: Yup.array().of(
      Yup.string()
        .matches(Regex.VEHICLE_NUMBER, "Vehicle Number should be valid")
        .required("Vehicle Number is required")
    ),
    remark: Yup.string().trim(),
  }),

  editPhotoShootRequest: Yup.object()
    .shape({
      dealer_id: Yup.number().required("Dealer Name is required"),
      photoshoot_type_id: Yup.number().required("PhotoShoot Type is required"),
      shoot_count: Yup.number()
        .min(1, "Count of Shoots must be at least 1")
        .required("Count of Shoots is required"),
      location: Yup.object().shape({
        address: Yup.string(),
        pincode: Yup.string(),
        state: Yup.string(),
        city: Yup.string(),
        latitude: Yup.string().matches(
          /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)/,
          "Invalid latitude"
        ),
        longitude: Yup.string().matches(
          /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)/,
          "Invalid longitude"
        ),
      }),
      schDateTime: Yup.string().required("Schedule Date is required"),
      schSlotId: Yup.string().required("Schedule Slot is required"),
      prefDateTime: Yup.string().when("status_name", {
        is: (val) => val == 2 || val == 3,
        then: () => Yup.string().required("Preferred Date is required"),
        otherwise: () => Yup.string().nullable(true),
      }),
      prefSlotId: Yup.string().when("status_name", {
        is: (val) => val == 2 || val == 3,
        then: () => Yup.string().required("Preferred Slot is required"),
        otherwise: () => Yup.string().nullable(true),
      }),
      // gst_type: Yup.string().trim().required("Gst Type is required"),
      gst_type: Yup.string().when("is_price_added", {
        is: true,
        then: () =>
          Yup.string()
            .required("Gst Type is required")
            .oneOf(
              ["GST_Included", "GST_Excluded", "GST_Not_Applicable"],
              "Invalid Gst Type"
            ),
        otherwise: () => Yup.string().notRequired(),
      }),
      is_price_added: Yup.boolean(),
      quantity: Yup.number().when("is_price_added", {
        is: true,
        then: () => Yup.number().required("Quantity is required"),
        otherwise: () => Yup.number().notRequired(),
      }),
      agreed_amount: Yup.number()
        .typeError("Agreed Amount must be a number")
        .when("is_price_added", {
          is: true,
          then: () =>
            Yup.number()
              .required("Agreed Amount is required")
              .test(
                "is-number",
                "Agreed Amount must only contain digits or up to two decimal places",
                (value) => /^\d+(\.\d{1,2})?$/.test(value.toString())
              ),
          otherwise: () => Yup.number().notRequired(),
        }),
      total_amount: Yup.number().when("is_price_added", {
        is: true,
        then: () => Yup.number().required("Total Amount is required"),
        otherwise: () => Yup.number().notRequired(),
      }),
      rc_no_arr: Yup.array().of(
        Yup.string()
          .matches(Regex.VEHICLE_NUMBER, "Vehicle Number should be valid")
          .required("Vehicle Number is required")
      ),
      remark: Yup.string().trim(),
      status: Yup.number().required("Status is required"),
      assigned_to: Yup.number().nullable(true),
    })
    .test(
      "price-added-when-completed",
      "Price must be added to mark the status as completed",
      function (value) {
        const { status, is_price_added } = value;
        if (status === 3 && !is_price_added) {
          return this.createError({
            path: "is_price_added",
            message: "Price must be added to mark the status as completed",
          });
        }
        return true;
      }
    ),

  addDigitalMarketingRequest: Yup.object().shape({
    dealer_id: Yup.number().required("Dealer Name is required"),
    DMHandle: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.number().required(
            "SM Handle must have a value and it must be a number"
          ),
          label: Yup.string().required("Each SM Handle must have a label"),
        })
      )
      .min(1, "At least one SM Handle must be selected")
      .required("SM Handles are required"),

    // add_ons_id: Yup.number().required("Add Ons Type is required"),
    DMAddOn: Yup.array().of(
      Yup.object().shape({
        value: Yup.number().required(
          "Add Ons must have a value and it must be a number"
        ),
        label: Yup.string().required("Add Ons must have a label"),
      })
    ),
    DMSubscription: Yup.number().required("Subscription Type is required"),
    startDate: Yup.string().required("Start Date is required"),
    endDate: Yup.string().required("End Date is required"),
    is_price_added: Yup.boolean(),
    gst_type: Yup.string().when("is_price_added", {
      is: true,
      then: () =>
        Yup.string()
          .required("Gst Type is required")
          .oneOf(
            ["GST_Included", "GST_Excluded", "GST_Not_Applicable"],
            "Invalid Gst Type"
          ),
      otherwise: () => Yup.string().notRequired(),
    }),
    agreedAmount: Yup.number()
      .typeError("Agreed Amount must be a number")
      .when("is_price_added", {
        is: true,
        then: () =>
          Yup.number()
            .required("Agreed Amount is required")
            .test(
              "is-number",
              "Agreed Amount must only contain digits or up to two decimal places",
              (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        otherwise: () => Yup.number().notRequired(),
      }),
    totalAmount: Yup.number().when("is_price_added", {
      is: true,
      then: () => Yup.number().required("Net Price is required"),
      otherwise: () => Yup.number().notRequired(),
    }),
    perDayBudget: Yup.number().when("is_price_added", {
      is: true,
      then: () => Yup.number().required("Per Day Price is required"),
      otherwise: () => Yup.number().notRequired(),
    }),
    remark: Yup.string().trim(),
  }),

  editDigitalMarketingRequest: Yup.object()
    .shape({
      dealer_id: Yup.number().required("Dealer Name is required"),
      DMHandle: Yup.array()
        .of(
          Yup.object().shape({
            value: Yup.number().required(
              "SM Handle must have a value and it must be a number"
            ),
            label: Yup.string().required("Each SM Handle must have a label"),
          })
        )
        .min(1, "At least one SM Handle must be selected")
        .required("SM Handles are required"),
      DMAddOn: Yup.array().of(
        Yup.object().shape({
          value: Yup.number().required(
            "Add Ons must have a value and it must be a number"
          ),
          label: Yup.string().required("Add Ons must have a label"),
        })
      ),
      DMSubscription: Yup.number().required("Subscription Type is required"),
      startDate: Yup.string().required("Start Date is required"),
      endDate: Yup.string().required("End Date is required"),
      is_price_attached: Yup.boolean(),
      gst_type: Yup.string().when("is_price_added", {
        is: true,
        then: () =>
          Yup.string()
            .required("Gst Type is required")
            .oneOf(
              ["GST_Included", "GST_Excluded", "GST_Not_Applicable"],
              "Invalid Gst Type"
            ),
        otherwise: () => Yup.string().notRequired(),
      }),
      agreedAmount: Yup.number()
        .typeError("Agreed Amount must be a number")
        .when("is_price_attached", {
          is: true,
          then: () =>
            Yup.number()
              .required("Agreed Amount is required")
              .test(
                "is-number",
                "Agreed Amount must only contain digits or up to two decimal places",
                (value) => /^\d+(\.\d{1,2})?$/.test(value.toString())
              ),
          otherwise: () => Yup.number().notRequired(),
        }),
      totalAmount: Yup.number().when("is_price_attached", {
        is: true,
        then: () => Yup.number().required("Total Amount is required"),
        otherwise: () => Yup.number().notRequired(),
      }),
      perDayBudget: Yup.number().when("is_price_attached", {
        is: true,
        then: () => Yup.number().required("Per Day Price is required"),
        otherwise: () => Yup.number().notRequired(),
      }),
      remarks: Yup.string().trim(),
      status: Yup.number().required("Status is required"),
      adminAssigned: Yup.number().nullable(true),
    })
    .test(
      "price-added-when-completed",
      "Price must be added to mark the status as completed",
      function (value) {
        const { status, is_price_attached } = value;
        if (status === 3 && !is_price_attached) {
          console.log("Validation triggered for price and completed status.");
          return this.createError({
            path: "is_price_attached",
            message: "Price must be added to mark the status as completed",
          });
        }
        return true;
      }
    ),

  addWebsiteRequest: Yup.object().shape({
    dealer_id: Yup.number().required("Dealer Name is required"),
    // add_ons_id: Yup.number().required("Add Ons Type is required"),
    add_ons_id: Yup.array().of(
      Yup.object().shape({
        value: Yup.number().required(
          "Add Ons must have a value and it must be a number"
        ),
        label: Yup.string().required("Add Ons must have a label"),
      })
    ),
    subscription_id: Yup.number().required("Subscription Type is required"),
    website_type: Yup.number().required("Website Type is required"),
    start_date: Yup.string().required("Start Date is required"),
    end_date: Yup.string().required("End Date is required"),
    is_price_added: Yup.boolean(),
    gst_type: Yup.string().when("is_price_added", {
      is: true,
      then: () =>
        Yup.string()
          .required("Gst Type is required")
          .oneOf(
            ["GST_Included", "GST_Excluded", "GST_Not_Applicable"],
            "Invalid Gst Type"
          ),
      otherwise: () => Yup.string().notRequired(),
    }),
    agreed_amount: Yup.number()
      .typeError("Agreed Amount must be a number")
      .when("is_price_added", {
        is: true,
        then: () =>
          Yup.number()
            .required("Agreed Amount is required")
            .test(
              "is-number",
              "Agreed Amount must only contain digits or up to two decimal places",
              (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        otherwise: () => Yup.number().notRequired(),
      }),
    total_amount: Yup.number().when("is_price_added", {
      is: true,
      then: () => Yup.number().required("Net Price is required"),
      otherwise: () => Yup.number().notRequired(),
    }),
    remark: Yup.string().trim(),
  }),

  editWebsiteDevelopmentRequest: Yup.object()
    .shape({
      dealer_id: Yup.number().required("Dealer Name is required"),
      add_ons_id: Yup.array().of(
        Yup.object().shape({
          value: Yup.number().required(
            "Add Ons must have a value and it must be a number"
          ),
          label: Yup.string().required("Add Ons must have a label"),
        })
      ),
      subscriptions_id: Yup.number().required("Subscription Type is required"),
      website_type: Yup.number().required("Website Type is required"),
      start_date: Yup.string().required("Start Date is required"),
      end_date: Yup.string().required("End Date is required"),
      is_price_attached: Yup.boolean(),
      gst_type: Yup.string().when("is_price_added", {
        is: true,
        then: () =>
          Yup.string()
            .required("Gst Type is required")
            .oneOf(
              ["GST_Included", "GST_Excluded", "GST_Not_Applicable"],
              "Invalid Gst Type"
            ),
        otherwise: () => Yup.string().notRequired(),
      }),
      agreed_amount: Yup.number()
        .typeError("Agreed Amount must be a number")
        .when("is_price_added", {
          is: true,
          then: () =>
            Yup.number()
              .required("Agreed Amount is required")
              .test(
                "is-number",
                "Agreed Amount must only contain digits or up to two decimal places",
                (value) => /^\d+(\.\d{1,2})?$/.test(value.toString())
              ),
          otherwise: () => Yup.number().notRequired(),
        }),
      total_amount: Yup.number().when("is_price_attached", {
        is: true,
        then: () => Yup.number().required("Total Amount is required"),
        otherwise: () => Yup.number().notRequired(),
      }),
      remarks: Yup.string().trim(),
      status: Yup.number().required("Status is required"),
      admin_assigned: Yup.number().nullable(true),
    })
    .test(
      "price-added-when-completed",
      "Price must be added to mark the status as completed",
      function (value) {
        const { status, is_price_attached } = value;
        if (status === 3 && !is_price_attached) {
          return this.createError({
            path: "is_price_attached",
            message: "Price must be added to mark the status as completed",
          });
        }
        return true;
      }
    ),

  addSocialMediaRequest: Yup.object().shape({
    dealer_id: Yup.number().required("Dealer Name is required"),
    sm_handles_id: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.number().required(
            "SM Handle must have a value and it must be a number"
          ),
          label: Yup.string().required("Each SM Handle must have a label"),
        })
      )
      .min(1, "At least one SM Handle must be selected")
      .required("SM Handles are required"),
    add_ons_id: Yup.array().of(
      Yup.object().shape({
        value: Yup.number().required(
          "Add Ons must have a value and it must be a number"
        ),
        label: Yup.string().required("Add Ons must have a label"),
      })
    ),
    subscription_id: Yup.number().required("Subscription Type is required"),
    monthly_creatives: Yup.number().required(
      "Monthly Still Creatives is required"
    ),
    monthly_reels: Yup.number().required("Monthly Reels is required"),
    start_date: Yup.string().required("Start Date is required"),
    end_date: Yup.string().required("End Date is required"),
    is_price_added: Yup.boolean(),
    gst_type: Yup.string().when("is_price_added", {
      is: true,
      then: () =>
        Yup.string()
          .required("Gst Type is required")
          .oneOf(
            ["GST_Included", "GST_Excluded", "GST_Not_Applicable"],
            "Invalid Gst Type"
          ),
      otherwise: () => Yup.string().notRequired(),
    }),
    inv_delivery_shoot: Yup.number().when("is_price_added", {
      is: true,
      then: () => Yup.number().required("Inventory Shots is required"),
      otherwise: () => Yup.number().notRequired(),
    }),
    agreed_amount: Yup.number()
      .typeError("Agreed Amount must be a number")
      .when("is_price_added", {
        is: true,
        then: () =>
          Yup.number()
            .required("Agreed Amount is required")
            .test(
              "is-number",
              "Agreed Amount must only contain digits or up to two decimal places",
              (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        otherwise: () => Yup.number().notRequired(),
      }),
    total_amount: Yup.number().when("is_price_added", {
      is: true,
      then: () => Yup.number().required("Net Price is required"),
      otherwise: () => Yup.number().notRequired(),
    }),
    remark: Yup.string().trim(),
  }),

  editSocialMediaRequest: Yup.object()
    .shape({
      dealer_id: Yup.number().required("Dealer Name is required"),
      sm_handle_id: Yup.array()
        .of(
          Yup.object().shape({
            value: Yup.number().required(
              "SM Handle must have a value and it must be a number"
            ),
            label: Yup.string().required("Each SM Handle must have a label"),
          })
        )
        .min(1, "At least one SM Handle must be selected")
        .required("SM Handles are required"),
      sm_add_ons: Yup.array().of(
        Yup.object().shape({
          value: Yup.number().required(
            "Add Ons must have a value and it must be a number"
          ),
          label: Yup.string().required("Add Ons must have a label"),
        })
      ),
      subscriptions_id: Yup.number().required("Subscription Type is required"),
      start_date: Yup.string().required("Start Date is required"),
      end_date: Yup.string().required("End Date is required"),
      monthly_creatives: Yup.number().required("Monthly Creatives is required"),
      monthly_reels: Yup.number().required("Monthly Reels is required"),
      is_price_attached: Yup.boolean(),
      gst_type: Yup.string().when("is_price_attached", {
        is: true,
        then: () =>
          Yup.string()
            .required("Gst Type is required")
            .oneOf(
              ["GST_Included", "GST_Excluded", "GST_Not_Applicable"],
              "Invalid Gst Type"
            ),
        otherwise: () => Yup.string().notRequired(),
      }),

      inv_delivery_shoot: Yup.number().when("is_price_attached", {
        is: true,
        then: () =>
          Yup.number().required("Inventory or Delivery Shoots is required"),
        otherwise: () => Yup.number().notRequired(),
      }),
      agreed_amount: Yup.number()
        .typeError("Agreed Amount must be a number")
        .when("is_price_attached", {
          is: true,
          then: () =>
            Yup.number()
              .required("Agreed Amount is required")
              .test(
                "is-number",
                "Agreed Amount must only contain digits or up to two decimal places",
                (value) => /^\d+(\.\d{1,2})?$/.test(value.toString())
              ),
          otherwise: () => Yup.number().notRequired(),
        }),
      total_amount: Yup.number().when("is_price_attached", {
        is: true,
        then: () => Yup.number().required("Total Amount is required"),
        otherwise: () => Yup.number().notRequired(),
      }),
      remarks: Yup.string().trim(),
      status: Yup.number().required("Status is required"),
      admin_assigned: Yup.number().nullable(true),
    })
    .test(
      "price-added-when-completed",
      "Price must be added to mark the status as completed",
      function (value) {
        const { status, is_price_attached } = value;
        if (status === 3 && !is_price_attached) {
          return this.createError({
            path: "is_price_attached",
            message: "Price must be added to mark the status as completed",
          });
        }
        return true;
      }
    ),

  addInsCusDetailsSchema: Yup.object().shape({
    phone_number: Yup.string()
      .trim()
      .matches(Regex.MOBILE, "Phone number must be valid upto 10 digits.")
      .required("Phone number is required"),
    customer_name: Yup.string()
      .trim()
      .matches(
        /^\S.*\S$|^\S$/,
        "Customer Name cannot have leading or trailing spaces"
      )
      .matches(Regex.DEALERSHIP_NAME, " Customer Name contains only alphabates")
      .max(30, "Name contains 30 characters only")
      .required("Customer Name is required"),
    email: Yup.string().email("Invalid email"),
    // .required("Email is required"),
    lead_status: Yup.string().required("Lead Status is required"),
    permanent_address: Yup.string()
      .required("Permanent Address is required")
      .matches(Regex.ADDRESS, "Permanent Address is not valid"),
    permanent_address_pincode: Yup.string()
      .trim()
      .matches(Regex.PINCODE, "Invalid pincode")
      .required("Pincode is required"),
    permanent_address_city: Yup.string().nullable(true),
    // .required(
    //   "Permanent Address City is required"
    // ),
    permanent_address_state: Yup.string().nullable(true),
    // .required(
    //   "Permanent Address State is required"
    // ),
    as_permanent: Yup.boolean().default(false),
    current_address: Yup.string().when("as_permanent", {
      is: false,
      then: () =>
        Yup.string()
          .trim()
          .matches(Regex.ADDRESS, "Current Address is not valid")
          .required("Current Address is required"),
      otherwise: () => Yup.string(),
    }),
    current_address_pincode: Yup.string().when("as_permanent", {
      is: false,
      then: () =>
        Yup.string()
          .matches(Regex.PINCODE, "Invalid pincode")
          .required("Pincode is required"),
      otherwise: () => Yup.string(),
    }),
    current_address_city: Yup.string().nullable(true),
    // .required(
    //   "Current Address City is required"
    // ),
    current_address_state: Yup.string().nullable(true),
    // .required(
    //   "Current Address State is required"
    // ),
    dealer_id: Yup.number().required("Dealership Name & Code is required"),
    lead_source: Yup.string().required("Lead Source is required"),
    rc_no: Yup.string()
      .matches(Regex.VEHICLE_NUMBER, "Invalid Vehicle Number")
      .required("Vehicle Number is required"),
    rc_document: Yup.string().required("Vehicle RC document is required"),
  }),

  editInsCusDetailsSchema: Yup.object().shape({
    phone_number: Yup.string()
      .trim()
      .matches(Regex.MOBILE, "Phone number must be valid upto 10 digits.")
      .required("Phone number is required"),
    customer_name: Yup.string()
      .trim()
      .matches(
        /^\S.*\S$|^\S$/,
        "Customer Name cannot have leading or trailing spaces"
      )
      .matches(Regex.DEALERSHIP_NAME, " Customer Name contains only alphabates")
      .max(30, "Name contains 30 characters only")
      .required("Customer Name is required"),
    email: Yup.string().email("Email is invalid"),
    lead_status: Yup.string().required("Lead Status is required"),
    permanent_address: Yup.string()
      .required("Permanent Address is required")
      .matches(Regex.ADDRESS, "Permanent Address is not valid"),
    permanent_address_pincode: Yup.string()
      .trim()
      .matches(Regex.PINCODE, "Invalid pincode")
      .required("Pincode is required"),
    permanent_address_city: Yup.string().nullable(true),
    // .required(
    //   "Permanent Address City is required"
    // ),
    permanent_address_state: Yup.string().nullable(true),
    // .required(
    //   "Permanent Address State is required"
    // ),
    as_permanent: Yup.boolean(),
    current_address: Yup.string().when("as_permanent", {
      is: false,
      then: () =>
        Yup.string()
          .trim()
          .matches(Regex.ADDRESS, "Current Address is not valid")
          .required("Current Address is required"),
      otherwise: () => Yup.string().notRequired(),
    }),
    current_address_pincode: Yup.string().when("as_permanent", {
      is: false,
      then: () =>
        Yup.string()
          .matches(Regex.PINCODE, "Invalid pincode")
          .required("Pincode is required"),
      otherwise: () => Yup.string().notRequired(),
    }),
    current_address_city: Yup.string().nullable(true),
    current_address_state: Yup.string().nullable(true),
    dealer_id: Yup.string(),
    lead_source: Yup.string().required("Lead Source is required"),
    rc_no: Yup.string()
      .matches(Regex.VEHICLE_NUMBER, "Invalid Vehicle Number")
      .required("Vehicle Number is required"),
    rc_document: Yup.string().required("Vehicle RC document is required"),
  }),

  // addInsKycDetailsSchema: Yup.object().shape({
  //   customer_id: Yup.string(),
  //   aadhar_data: Yup.object().shape({
  //     aadhar_number: Yup.string()
  //       .trim()
  //       .matches(Regex.AADHAAR, "Invalid Aadhaar Number")
  //       .required("Aadhaar Number is mandatory"),
  //     aadhar_document: Yup.array()
  //       .of(Yup.string())
  //       .max(2, "Max 2 docs allowed."),
  //     // file_path_1: Yup.string().required("Document Path is required"),
  //     // file_path_2: Yup.string().required("Document Path is required"),
  //     document_id: Yup.number().required("Document ID is required"),
  //   }),
  //   pan_number: Yup.string()
  //     .trim()
  //     .matches(Regex.PAN, "Invalid PAN")
  //     .required("PAN number is required"),
  //   documents_data: Yup.array()
  //     .of(
  //       Yup.object().shape({
  //         document_id: Yup.number().required("Document ID is required"),
  //         document_path: Yup.string().required("Document Path is required"),
  //       })
  //     )
  //     .required("At least one document is required")
  //     .min(1, "At least one document is required"),
  // }),

  addInsKycDetailsSchema: Yup.object().shape({
    // documents_data: Yup.array().of(
    //   Yup.object().shape({
    //     document_id: Yup.number().required("Document ID is required"),
    //     document_path: Yup.string()
    //       .nullable()
    //       .test(
    //         "required-file",
    //         "Please upload the document",
    //         (value, context) => {
    //           const docId = context.parent.document_id;
    //           return (docId !== 1 && !!value) || docId === 1;
    //         }
    //       ),
    //   })
    // ),
    aadhar_data: Yup.object().shape({
      aadhar_document: Yup.array()
        // .test(
        //   "not-all-null",
        //   "Please select atleast one aadhar doc",
        //   (value) => {
        //     return value && value.every((file) => file != null);
        //   }
        // )
        .min(1, "Please upload front and back sides of aadhar card")
        .nullable(),
      aadhar_number: Yup.string()
        .trim()
        .matches(Regex.AADHAAR, "Invalid Aadhaar Number"),
      // .required("Aadhaar Number is required"),
    }),
    pan_number: Yup.string()
      .trim()
      .matches(Regex.PAN, "Invalid PAN")
      .required("PAN number is required"),
  }),

  editInsKycDetailsSchema: Yup.object().shape({
    documents_data: Yup.array().of(
      Yup.object().shape({
        document_id: Yup.number().required("Document ID is required"),
        document_path: Yup.string().nullable(),
        // .test("required-file", "Please upload the document", (value, context) => {
        //   const docId = context.parent.document_id;
        //   return (
        //     (docId !== 1 && !!value && docId !== 3 && !!value) || (docId === 1 && docId === 1)
        //   );
        // }),
      })
    ),
    aadhar_data: Yup.object().shape({
      aadhar_document: Yup.array()
        .min(1, "Please upload at least one document proof")
        .nullable(),
      aadhar_number: Yup.string().nullable(),
      // .required("Aadhaar Number is required"),
    }),
    pan_number: Yup.string().nullable(),
  }),

  addInsurancePayoutsDetails: Yup.object().shape({
    // ins_file_id: Yup.string(),
    insurance_payout: Yup.number().required(
      "Insurance Received Payout is required"
    ),
    insurance_payout_status: Yup.number().required(
      "Insurance Payout Status is required"
    ),
    insurance_partner_name: Yup.string().nullable(true),
    insurance_partner_code: Yup.string(),
    insurance_partner_bank_account: Yup.string(),
    insurance_partner_ifsc_code: Yup.string(),
    insurance_partner_payout: Yup.string()
      .matches(Regex.NUMBER, "Partner Payout should be number only")
      .required("Insurance Partner Payout is required"),
    pnl: Yup.number(),
    // .matches(Regex.NUMBER, "Pnl should be number only")
    // .required("Pnl is required"),
  }),

  editInsurancePayoutsDetails: Yup.object().shape({
    // ins_file_id: Yup.string(),
    insurance_payout: Yup.number().required(
      "Insurance Received Payout is required"
    ),
    insurance_payout_status: Yup.number().required(
      "Insurance Payout Status is required"
    ),
    insurance_partner_name: Yup.string().nullable(true),
    insurance_partner_code: Yup.string(),
    insurance_partner_bank_account: Yup.string(),
    insurance_partner_ifsc_code: Yup.string(),
    insurance_partner_payout: Yup.string()
      .matches(Regex.NUMBER, "Partner Payout should be number only")
      .required("Insurance Partner Payout is required"),
    pnl: Yup.number(),
    // .matches(Regex.NUMBER, "Pnl should be number only")
    // .required("Pnl is required"),
  }),
  addInsuranceDetails: Yup.object().shape({
    insurance_category: Yup.string().required("Insurance Category is required"),
    insurance_SubCategory: Yup.string().required(
      "Insurance Sub Category is required"
    ),
    insurance_type: Yup.string().required("Insurance Type is required"),
    insurance_addons: Yup.array().of(Yup.number()),
    insurance_premium: Yup.number().nullable(true),
    insurance_status: Yup.string().nullable(true),
    insurance_policyNumber: Yup.string().nullable(true),
    insurance_policyIssuedDate: Yup.date().nullable(true),
    insurance_policyExpiryDate: Yup.date()
      // .required("Policy expiry date is required")
      .min(
        Yup.ref("insurance_policyIssuedDate"),
        "Expiry date cannot be before issued date"
      )
      .nullable(true),
    assigned_to: Yup.string().nullable(true),
    ins_file_id: Yup.string().nullable(true),
    previous_policy_document: Yup.string().nullable(true),
    // insurance_tableData: Yup.array().of(
    //   Yup.object().shape({
    //     coverage_type: Yup.string().when("ins_file_id", {
    //       is: (ins_file_id) => !ins_file_id, // If ins_file_id is NOT available, require all fields
    //       then: () => Yup.string().required("Coverage Amount is required"),
    //       // .required("Coverage Type is required"),
    //       otherwise: () => Yup.string().nullable(true),
    //     }),
    //     sum_insured: Yup.number().when("ins_file_id", {
    //       is: (ins_file_id) => !ins_file_id,
    //       then: () => Yup.number().required("Sum Insured is required"),
    //       // .required("Sum insured is required"),
    //       otherwise: () => Yup.number(),
    //     }),
    //     premium_amount: Yup.number().nullable(true),
    //     premium_frequency: Yup.string().nullable(true),
    //     net_payable_premium: Yup.number().nullable(true),
    //     quotation_file: Yup.mixed().nullable(true),
    //     policy_number: Yup.string().nullable(true),
    //     policy_issued_date: Yup.date().nullable(true),
    //     policy_expiry_date: Yup.date().nullable(true),
    //     insurance_AssignedTo: Yup.string().nullable(true),
    //   })
    // ),
  }),

  editInsuranceDetails: Yup.object().shape({
    insurance_category: Yup.string().required("Insurance category is required"),
    insurance_SubCategory: Yup.string().required(
      "Insurance Sub Category is required"
    ),
    insurance_type: Yup.string().required("Insurance Type is required"),
    insurance_addons: Yup.array().of(Yup.number()),
    insurance_premium: Yup.number().nullable(true),
    insurance_status: Yup.string().nullable(true),
    insurance_policyNumber: Yup.string().nullable(true),
    insurance_policyIssuedDate: Yup.date().nullable(true),
    insurance_policyExpiryDate: Yup.date()
      // .required("Policy expiry date is required")
      .min(
        Yup.ref("insurance_policyIssuedDate"),
        "Expiry date cannot be before issued date"
      )
      .nullable(true),
    assigned_to: Yup.string().nullable(true),
    previous_policy_document: Yup.string().nullable(true),
    // insurance_tableData: Yup.array().of(
    //   Yup.object().shape({
    //     coverage_type: Yup.string().nullable(true),
    //     ins_case_id: Yup.string().nullable(true),
    //     sum_insured: Yup.number().nullable(true),
    //     premium_amount: Yup.number().nullable(true),
    //     premium_frequency: Yup.string().nullable(true),
    //     net_payable_premium: Yup.number().nullable(true),
    //     quotation_file: Yup.string().nullable(true),
    //     policy_number: Yup.string().nullable(true),
    //     policy_issued_date: Yup.date().nullable(true),
    //     policy_expiry_date: Yup.date()
    //       .nullable(true)
    //       // .required("Policy expiry date is required")
    //       .min(
    //         Yup.ref("policy_issued_date"),
    //         "Expiry date cannot be before issued date"
    //       ),
    //     assigned_to: Yup.string().nullable(true),
    //   })
    // ),
  }),
  storeAddressSchema: Yup.object().shape({
    storeName: Yup.string().required("Store Name is required"),
    address: Yup.string().required("Address is required"),
    pincode: Yup.string()
      .length(6, "Pincode must be 6 digits")
      .required("Pincode is required"),
    city: Yup.string().required("city is required"),
    state: Yup.string().required("state is required"),

    // latitude: Yup.number().required('Latitude is required').typeError('Latitude must be a number'),
    // longitude: Yup.number().required('Longitude is required').typeError('Longitude must be a number'),
    // gLocationCode: Yup.string().required('GLocation Code is required'),
  }),

  // editInsuranceDetails: Yup.object().shape({
  //   insurance_category: Yup.string().required("Insurance category is required"),
  //   insurance_SubCategory: Yup.string().required("Insurance subcategory is required"),
  //   insurance_type: Yup.string().required("Insurance Type is required"),
  //   insurance_addons: Yup.array().of(Yup.number()),
  //   insurance_status: Yup.string(),
  //   insurance_policyNumber: Yup.string(),
  //   insurance_policyIssuedDate: Yup.date().nullable(true),
  //   insurance_policyExpiryDate: Yup.date().required("Policy expiry date is required").min(Yup.ref("insurance_policyIssuedDate"), "Expiry date cannot be before issued date").nullable(true),
  //   assigned_to: Yup.string(),
  //   insurance_tableData: Yup.array().of(
  //     Yup.object().shape({
  //       coverage_type: Yup.string(),
  //       ins_case_id: Yup.string(),
  //       sum_insured: Yup.number(),
  //       premium_amount: Yup.number(),
  //       premium_frequency: Yup.string(),
  //       net_payable_premium: Yup.number(),
  //       quotation_file: Yup.string(),
  //       policy_number: Yup.string(),
  //       policy_issued_date: Yup.date(),
  //       policy_expiry_date: Yup.date()
  //         // .required("Policy expiry date is required")
  //         .min(Yup.ref("policy_issued_date"), "Expiry date cannot be before issued date"),
  //       otherwise: () => Yup.date(),
  //       insurance_AssignedTo: Yup.string(),
  //       status: Yup.string(),
  //     })
  //   ),
  // }),

  lendersSchema: Yup.object().shape({
    lender_data: Yup.array().of(
      Yup.object().shape({
        file_id: Yup.number(),
        lender_application_id: Yup.string().nullable(true),
        lender_type: Yup.number()
          .required("Lender Type is required")
          .positive("Lender Type must be a positive number")
          .integer("Lender Type must be an integer"),
        lender_id: Yup.number().required("Lender ID is required").nullable(),
        lender_name: Yup.string().required("Lender name"),
        loan_amount: Yup.number()
          .required("Loan Amount is required")
          .positive("Loan Amount must be a positive number")
          .max(10000000, "Loan Amount cannot exceed 10 million"),
        interest_type: Yup.number().nullable(),
        roi: Yup.string()
          .matches(/^(100|[1-9]?\d(\.\d{1,2})?)$/, "Inavlid Roi")
          .min(0, "ROI cannot be less than 0")
          .max(100, "ROI cannot exceed 100")
          .nullable(true),
        tenure: Yup.number()
          .nullable()
          .min(1, "Tenure must be at least 1")
          .max(600, "Tenure cannot exceed 600 months"),
        tenure_type: Yup.string().nullable(true),
        status: Yup.number(),
      })
    ),
  }),

  lenderSchema: Yup.object().shape({
    file_id: Yup.string().nullable(),
    lender_application_id: Yup.string().nullable(true),
    lender_type: Yup.number("Lender Type is required").required(
      "Lender Type is required"
    ),
    lender_id: Yup.number("Lender ID is required").when("lender_type", {
      is: (lender_type) => lender_type == 1 || lender_type == 2,
      then: () => Yup.number().required("Lender ID is required").nullable(),
      otherwise: () => Yup.number().nullable(),
    }),
    lender_name: Yup.string().when("lender_type", {
      is: (lender_type) => lender_type != 1 && lender_type != 2,
      then: () =>
        Yup.string("Lender Name is required").required(
          "Lender Name is required"
        ),
      otherwise: () => Yup.string().nullable(),
    }),
    loan_amount: Yup.number("Loan Amount should be in digits")
      .required("Loan Amount is required")
      .positive("Loan Amount must be a positive number")
      .max(10000000, "Loan Amount cannot exceed 10 million"),
    status: Yup.number(),
    sanctioned_date: Yup.date().nullable(true),
    sanctioned_amount: Yup.number().nullable(true),
    interest_type: Yup.string().nullable(),
    roi: Yup.string()
      .matches(/^(100|[1-9]?\d(\.\d{1,2})?)$/, "Inavlid Roi")
      .min(0, "ROI cannot be less than 0")
      .max(100, "ROI cannot exceed 100")
      .nullable(true),
    tenure: Yup.number()
      .nullable()
      .min(1, "Tenure must be at least 1")
      .max(600, "Tenure cannot exceed 600 months"),
    tenure_type: Yup.string().nullable(true),
  }),

  addCreativeMakingRequest: Yup.object().shape({
    dealer_id: Yup.number().required("Dealer Name is required"),
    creative_type: Yup.number().required("Creative Type is required"),
    rc_no: Yup.string().matches(Regex.VEHICLE_NUMBER, "Invalid Vehicle Number"),
    make_id: Yup.number().nullable(true),
    model_id: Yup.number().nullable(true),
    variant_id: Yup.number().nullable(true),
    year: Yup.string()
      .matches(/^\d{4}$/, "Must be exactly 4 digits")
      .nullable(true),
    registration_year: Yup.string()
      .matches(/^\d{4}$/, "Must be exactly 4 digits")
      .nullable(true),
    same_as_year: Yup.boolean().default(false),
    owner: Yup.number()
      .typeError("Ownership Count must be a single digit")
      .min(0, "Ownership Count must be between 0 and 9")
      .max(9, "Ownership Count must be between 0 and 9")
      .nullable(true),
    odometer_readings: Yup.number().nullable(true),
    remarks: Yup.string().trim().nullable(true),
    assigned_to: Yup.number().nullable(true),
    status: Yup.number().required("Status is required"),
    is_price_attached: Yup.boolean(),
    gst_amount: Yup.number().when("is_price_attached", {
      is: true,
      then: () => Yup.number().required("GST Amount is required"),
      otherwise: () => Yup.number().notRequired(),
    }),
    gst_type: Yup.string().when("is_price_attached", {
      is: true,
      then: () =>
        Yup.string()
          .required("Gst Type is required")
          .oneOf(
            ["GST_Included", "GST_Excluded", "GST_Not_Applicable"],
            "Invalid Gst Type"
          ),
      otherwise: () => Yup.string().notRequired(),
    }),
    count: Yup.number().when("is_price_attached", {
      is: true,
      then: () =>
        Yup.number()
          .min(1, "Count of Creative must be at least 1")
          .required("Count of Creative is required"),
      otherwise: () => Yup.number().notRequired(),
    }),
    agreed_amount: Yup.number()
      .typeError("Agreed Amount must be a number")
      .when("is_price_attached", {
        is: true,
        then: () =>
          Yup.number()
            .required("Agreed Amount is required")
            .test(
              "is-number",
              "Agreed Amount must only contain digits or up to two decimal places",
              (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        otherwise: () => Yup.number().notRequired(),
      }),
    consultancy: Yup.number().when("is_price_attached", {
      is: true,
      then: () => Yup.number().required("Consultancy is required"),
      otherwise: () => Yup.number().notRequired(),
    }),
    total_amount: Yup.number().when("is_price_attached", {
      is: true,
      then: () => Yup.number().required("Net Price is required"),
      otherwise: () => Yup.number().notRequired(),
    }),
    fuel: Yup.number(),
    transmission: Yup.number(),
    color: Yup.number().nullable(true),
    document: Yup.array()
      .of(Yup.string())
      .when("status", {
        is: 3,
        then: () =>
          Yup.array()
            .of(Yup.string())
            .max(10, "Can Upload 10 documents only")
            .required("Documents is required"),
        otherwise: () =>
          Yup.array().of(Yup.string()).max(10, "Can Upload 10 documents only"),
      }),
  }),

  creativeMakingBasicRequest: Yup.object().shape({
    fuel: Yup.number(),
    transmission: Yup.number(),
    color: Yup.number().nullable(true),
    dealer_id: Yup.number().required("Dealer Name is required"),
    creative_type: Yup.number().required("Creative Type is required"),
    rc_no: Yup.string().matches(Regex.VEHICLE_NUMBER, "Invalid Vehicle Number"),
    make_id: Yup.number().nullable(true),
    model_id: Yup.number().nullable(true),
    variant_id: Yup.number().nullable(true),
    year: Yup.string()
      .matches(/^\d{4}$/, "Must be exactly 4 digits")
      .nullable(true),
    registration_year: Yup.string()
      .matches(/^\d{4}$/, "Must be exactly 4 digits")
      .nullable(true),
    same_as_year: Yup.boolean().default(false),
    owner: Yup.number()
      .typeError("Ownership Count must be a single digit")
      .min(0, "Ownership Count must be between 0 and 9")
      .max(9, "Ownership Count must be between 0 and 9")
      .nullable(true),
    odometer_readings: Yup.number().nullable(true),
    remarks: Yup.string().trim().nullable(true),
    assigned_to: Yup.number().nullable(true),
    sample_car_document: Yup.string().nullable(true),
    status: Yup.number().nullable(true),
  }),

  insuranceLenderSchema: Yup.object().shape({
    ins_case_id: Yup.string().nullable(true),
    coverage_type: Yup.number().required("Coverage Amount is required"),
    sum_insured: Yup.number().required("Sum Insured is required"),
    premium_amount: Yup.number().nullable(true),
    premium_frequency: Yup.string().nullable(true),
    net_payable_premium: Yup.number().nullable(true),
    quotation_file: Yup.string().nullable(true),
    policy_number: Yup.string().nullable(true),
    policy_issued_date: Yup.date().nullable(true),
    policy_expiry_date: Yup.date()
      .nullable(true)
      // .required("Policy expiry date is required")
      .min(
        Yup.ref("policy_issued_date"),
        "Expiry date cannot be before issued date"
      ),
    assigned_to: Yup.string().nullable(true),
  }),

  editCreativeMakingRequest: Yup.object().shape({
    creative_type: Yup.number().required("Creative Type is required"),
    rc_no: Yup.string().matches(Regex.VEHICLE_NUMBER, "Invalid Vehicle Number"),
    // .required("Vehicle Number is required"),
    make_id: Yup.number().nullable(true),
    model_id: Yup.number().nullable(true),
    variant_id: Yup.number().nullable(true),
    year: Yup.string()
      .matches(/^\d{4}$/, "Must be exactly 4 digits")
      .nullable(true),
    // year: Yup.string()
    //   .matches(/^\d{4}$/, "Must be exactly 4 digits")
    //   .nullable(true),
    registration_year: Yup.string()
      .matches(/^\d{4}$/, "Must be exactly 4 digits")
      .nullable(true),
    // owner: Yup.number().nullable(true),
    owner: Yup.number()
      .typeError("Ownership Count must be a single digit")
      .min(0, "Ownership Count must be between 0 and 9")
      .max(9, "Ownership Count must be between 0 and 9")
      .nullable(true),
    same_as_year: Yup.boolean().default(false),
    odometer_readings: Yup.number().nullable(true),
    remarks: Yup.string().trim().nullable(true),
    assigned_to: Yup.number().nullable(true),
    fuel: Yup.number().nullable(true),
    transmission: Yup.number().nullable(true),
    sample_car_document: Yup.string().nullable(true),
    status: Yup.number().required("Status is required"),
    // creative_count: Yup.number().min(1, "Count of Creative must be at least 1"),
    is_price_attached: Yup.boolean(),
    gst_amount: Yup.number().when("is_price_attached", {
      is: true,
      then: () => Yup.number().required("GST Amount is required"),
      otherwise: () => Yup.number().notRequired(),
    }),
    gst_type: Yup.string().when("is_price_attached", {
      is: true,
      then: () =>
        Yup.string()
          .required("Gst Type is required")
          .oneOf(
            ["GST_Included", "GST_Excluded", "GST_Not_Applicable"],
            "Invalid Gst Type"
          ),
      otherwise: () => Yup.string().notRequired(),
    }),
    count: Yup.number().when("is_price_attached", {
      is: true,
      then: () =>
        Yup.number()
          .min(1, "Count of Creative must be at least 1")
          .required("Count of Creative is required"),
      otherwise: () => Yup.number().notRequired(),
    }),
    agreed_amount: Yup.number()
      .typeError("Agreed Amount must be a number")
      .when("is_price_attached", {
        is: true,
        then: () =>
          Yup.number()
            .required("Agreed Amount is required")
            .test(
              "is-number",
              "Agreed Amount must only contain digits or up to two decimal places",
              (value) => /^\d+(\.\d{1,2})?$/.test(value)
            ),
        otherwise: () => Yup.number().notRequired(),
      }),
    consultancy: Yup.number().when("is_price_attached", {
      is: true,
      then: () => Yup.number().required("Consultancy is required"),
      otherwise: () => Yup.number().notRequired(),
    }),
    total_amount: Yup.number().when("is_price_attached", {
      is: true,
      then: () => Yup.number().required("Net Price is required"),
      otherwise: () => Yup.number().notRequired(),
    }),
    document: Yup.array()
      .of(Yup.string())
      .when("status", {
        is: 3,
        then: () =>
          Yup.array()
            .of(Yup.string())
            .max(10, "Can Upload 10 documents only")
            .required("Documents is required"),
        otherwise: () =>
          Yup.array().of(Yup.string()).max(10, "Can Upload 10 documents only"),
      }),
  }),

  getVahanCheck: Yup.object().shape({
    dealer_id: Yup.number().required("Dealer is required"),
    rc_no: Yup.string()
      .matches(Regex.VEHICLE_NUMBER, "Vehicle No. should be valid")
      .required("Vehicle No. is required"),
  }),
};

export default validationSchema;
