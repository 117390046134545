import React, { useEffect, useRef, useState } from "react";
import style from "./style.module.scss";
import JoditEditor from "jodit-react";
import BackButton from "../../../components/buttons/BackButton";
import PrimaryHeading from "../../../components/primary-heading/PrimaryHeading";
import InputField from "../../../components/input-field/InputField";
import { IoEyeSharp, IoCloseSharp } from "react-icons/io5";
import { blogs } from "../../../api/blogs";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { dealerId } from "../../../constants/resources";

function CreateBlogs() {
  const [fileName, setFileName] = useState("");
  const [bannerName, setBannerName] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  const [bannerUrl, setBannerUrl] = useState(null);

  const { total } = useSelector((state) => state.buyerLead);
  const dealer_id = dealerId;
  const location = useLocation();
  const { id } = location.state || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editor = useRef(null);

  const [formData, setFormData] = useState({
    title: "",
    author: "",
    tags: "",
    description: "",
    banner: null,
    thumbnail: null,
    slugs: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await blogs.getDealerBlogs({ blog_id: id, all: 1 });
        const data = res.data;
        console.log('blogs-respnse --->', data);
        
        const blog = data[0];
        setFormData({
          title: blog?.title || "",
          author: blog?.author || "",
          slugs:
            formateSpacesWithDash(blog?.slugs) ||
            formateSpacesWithDash(blog?.title),
          tags: blog?.tags || "",
          description: blog?.description || "",
          banner: blog?.blog_banner || "",
          thumbnail: blog?.blog_logo || "",
        });
        // Set the URLs for previewing the images
        if (blog?.blog_logo) {
          setThumbnailUrl(blog.blog_logo);
          setFileName(blog.blog_logo.split("/").pop()); // Extract file name from URL
        }
        if (blog?.blog_banner) {
          setBannerUrl(blog.blog_banner);
          setBannerName(blog.blog_banner.split("/").pop()); // Extract file name from URL
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: file,
    }));

    if (name === "thumbnail") {
      setFileName(file?.name || "");
      setThumbnailUrl(file ? URL.createObjectURL(file) : null);
    } else if (name === "banner") {
      setBannerName(file?.name || "");
      setBannerUrl(file ? URL.createObjectURL(file) : null);
    }
  };

  const handleRemoveFile = (fileType) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fileType]: null,
    }));

    if (fileType === "thumbnail") {
      setFileName("");
      setThumbnailUrl(null);
    } else if (fileType === "banner") {
      setBannerName("");
      setBannerUrl(null);
    }
  };

  const formateSpacesWithDash = (str) => {
    if (!str) return "";
    return str.trim().replace(/\s+/g, "-").toLowerCase();
  };

  const handleSaveDraft = async (e) => {
    e.preventDefault();
    const status = 2;
    const formDatas = new FormData();
    if (status) {
      formDatas.append("status", status);
    }
    if (id) {
      formDatas.append("dealer_blogs_id", id);
    }
    Object.keys(formData).forEach((key) => {
      formDatas.append(key, formData[key]);
    });

    try {
      await blogs.saveDealerBlogs({ dealer_id: dealer_id }, formDatas, id, status);
      toast.success("Saved successfully!", {
        autoClose: 3000,
      });
      navigate("/dealers-blogs");
    } catch (error) {
      toast.error("Failed to save blog!", {
        autoClose: 3000,
      });
      console.error(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === "slugs") {
      formattedValue = formateSpacesWithDash(value);
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: formattedValue,
    }));

    if (name === "title" && !id) {
      setFormData((prevData) => ({
        ...prevData,
        slugs: formateSpacesWithDash(value),
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const status = 1;
    const requiredFields = ["title", "author", "tags"];
    const isValid = requiredFields.every(
      (field) => formData[field].trim() !== ""
    );

    if (!isValid) {
      alert("Please fill in all required fields");
      return;
    }

    const formDatas = new FormData();
    if (status) {
      formDatas.append("status", status);
    }
    if (id) {
      formDatas.append("dealer_blogs_id", id);
    }

    // Capture the timestamp
    const published_date = new Date()
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    formDatas.append("published_date", published_date);

    Object.keys(formData).forEach((key) => {
      formDatas.append(key, formData[key]);
    });

    try {
      await blogs.saveDealerBlogs(
        { dealer_id: dealer_id },
        formDatas,
        status,
        published_date
      );
      toast.success("Saved successfully!", {
        autoClose: 3000,
      });
      navigate("/dealers-blogs");
    } catch (error) {
      toast.error("Failed to save blog!", {
        autoClose: 3000,
      });
      console.error(error);
    }
  };

  return (
    <div className={style["container"]}>
      <div className={style["header-wrapper"]}>
        <BackButton onClick={() => navigate("/dealers-blogs")} />
        <PrimaryHeading
          title={"Add New Blog"}
          fontSize={"1.75rem"}
          fontWeight={500}
        />
      </div>
      <div className={style["form-wrapper"]}>
        <form onSubmit={handleSubmit} className={style["form-items"]}>
          <div className={style["basic-user-info"]}>
            {["title", "author", "tags"].map((field) => (
              <div key={field} className={style["input-label"]}>
                <p className={style["label"]}>{`${
                  field.charAt(0).toUpperCase() + field.slice(1)
                }:`}</p>
                <InputField
                  placeholder={`Enter Blog ${
                    field.charAt(0).toUpperCase() + field.slice(1)
                  }`}
                  name={field}
                  handleChange={handleChange}
                  className={style["input-field"]}
                  value={formData[field]}
                  required
                />
              </div>
            ))}

            <div className={style["input-label"]}>
              <p className={style["label"]}>Slug:</p>
              <InputField
                placeholder="Enter Blog Slug"
                name="slugs"
                handleChange={handleChange}
                className={style["input-field"]}
                value={formData.slugs}
                required
              />
            </div>
          </div>

          <div className={style["uploads"]}>
            <div className={style["file-wrapper"]}>
              <label>Thumbnail</label>
              <div className={style["right-side"]}>
                <input
                  id="thumbnailInput"
                  type="file"
                  onChange={handleFileChange}
                  name="thumbnail"
                  className={style["file-w"]}
                />
                {thumbnailUrl && (
                  <div className={style["icon-container"]}>
                    <a
                      href={thumbnailUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IoEyeSharp size={25} title="View Thumbnail" />
                    </a>
                    <IoCloseSharp
                      size={25}
                      className={style["remove-icon"]}
                      onClick={() => handleRemoveFile("thumbnail")}
                      style={{ cursor: "pointer", marginLeft: "8px" }}
                      title="Remove Thumbnail"
                    />
                  </div>
                )}
              </div>
            </div>

            <div className={style["file-wrapper"]}>
              <label>Banner</label>
              <div className={style["right-side"]}>
                <input
                  id="bannerInput"
                  type="file"
                  onChange={handleFileChange}
                  name="banner"
                  className={style["file-w"]}
                />
                {bannerUrl && (
                  <div className={style["icon-container"]}>
                    <a
                      href={bannerUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IoEyeSharp size={25} />
                    </a>
                    <IoCloseSharp
                      size={25}
                      className={style["remove-icon"]}
                      onClick={() => handleRemoveFile("banner")}
                      style={{ cursor: "pointer", marginLeft: "8px" }}
                      title="Remove Banner"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div>
            <label>Description:</label>
            <JoditEditor
              ref={editor}
              config={{ width: "1250px", height: "420px" }}
              value={formData.description}
              name="description"
              onBlur={(newContent) =>
                setFormData((val) => ({ ...val, description: newContent }))
              }
            />
          </div>

          <div className={style["buttons"]}>
            <div className={style["action-wrapper"]}>
              <input
                type="submit"
                value="Save as Draft"
                className={style["form-saved"]}
                onClick={handleSaveDraft}
              />
            </div>

            <div className={style["action-wrapper"]}>
              <input
                type="submit"
                value="Save & Publish"
                className={style["form-published"]}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateBlogs;
