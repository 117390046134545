import React, { useState, useEffect } from "react";
import style from "./carManagement.module.scss";
import PrimaryHeading from "../../components/primary-heading/PrimaryHeading";
import SearchBar from "../../components/search-bar/SearchBar";
// import SelectableButton from "../../components/buttons/SelectableButton";
import { R } from "../../constants/resources";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import CarDetails from "./car-details/CarDetails";
import DetailTable from "../../components/tables/DetailTable";
// import getData from "../../components/services/apiServices";
// import { useDispatch, useSelector } from "react-redux";
import { getCarList } from "../../store/car/carAction";
import { publicApi } from "../../api/publicApi";
import { useNavigate } from "react-router-dom";
import MultiSelect from "../../components/buttons/multiSelect";

export default function CarManagement() {
  // const dispatch = useDispatch();

  const [filter, setFilter] = useState({
    vehicle_id: "",
    make: "",
    model_id: "",
    varientName: "",
    status: "",
    dealerId: localStorage.getItem("dealerId"),
  });
  const [brandList, setBrandList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [varientList, setVarientList] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState();
  const [totalCount, setTotalCount] = useState();
  const [hasNextPage, setHasNextPage] = useState();

  const [carList, setCarList] = useState([]);

  const [data, setData] = useState([]);

  const brands = async () => {
    try {
      const res = await publicApi.brandList();

      setBrandList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const models = async () => {
    try {
      const res = await publicApi.modelList({ make: filter?.make });
      setModelList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const varients = async () => {
    try {
      const res = await publicApi.varientList({ model: filter?.model_id });
      setVarientList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchFilteredCarList = () => {
    const makeArray = Array.isArray(filter?.make)
      ? filter.make
      : [filter.make].filter(Boolean);
    const modelArray = Array.isArray(filter?.model_id)
      ? filter.model_id
      : [filter.model_id].filter(Boolean);

    getCarList({
      reg_no: filter?.vehicle_id || "",
      make: makeArray.length ? makeArray : undefined,
      model_id: modelArray.length ? modelArray : undefined,
      page: currentPage,
      car_status: filter?.status || "all",
      dealerId: +filter?.dealerId || "",
    })
      .then((response) => {
        setCarList(response.payload.data);
        setCurrentPage(response.payload.currentPage);
        setTotalPageCount(response.payload.totalPageCount);
        setTotalCount(response.payload.totalCount);
        setHasNextPage(response.payload.hasNextPage);
      })
      .catch((error) => {
        console.error("Error fetching filtered car list:", error);
      });
  };

  useEffect(() => {
    fetchFilteredCarList();
    brands();
  }, []);

  useEffect(() => {
    if (filter?.make) models();
  }, [filter?.make]);

  useEffect(() => {
    if (filter?.model_id) varients();
  }, [filter?.model_id]);

  useEffect(() => {
    fetchFilteredCarList();
  }, [filter, currentPage]);

  useEffect(() => {
    setData(carList);
  }, [carList]);

  const handleNextPage = () => {
    if (hasNextPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const [isDetailPageVisible, setIsDetailPageVisible] = useState(false);
  const navigate = useNavigate();

  return (
    <div className={style["container"]}>
      {isDetailPageVisible && (
        <CarDetails
          onClick={() => setIsDetailPageVisible(!isDetailPageVisible)}
        />
      )}

      <PrimaryHeading
        title={"Car Management"}
        count={totalCount}
        fontSize={"1.45rem"}
        fontWeight={600}
      />

      <div className={style["filters-container"]}>
        <div className={style["filters-wrapper"]}>
          <SearchBar
            placeholder={"Reg.No / SKU-ID"}
            value={filter?.vehicle_id}
            handleChange={(e) =>
              setFilter({ ...filter, vehicle_id: e.target.value })
            }
            name="vehicle_id"
          />

          <MultiSelect
            name="make"
            handleChange={(e) =>
              setFilter({ ...filter, make: e?.value, model_id: null })
            }
            items={brandList?.map((ele) => ({
              label: ele?.makeName,
              value: ele?.makeId,
            }))}
            selectedItems={filter?.make}
            hint={"All Brand"}
            isSearchable={true}
            containerStyle={style["selectable-button-container-style"]}
            selectClass={style["filterDropDown"]}
          />
          <MultiSelect
            name="model_id"
            handleChange={(e) => setFilter({ ...filter, model_id: e?.value })}
            items={modelList?.map((ele) => ({
              label: ele?.modelName,
              value: ele?.modelId,
            }))}
            selectedItems={filter?.model_id}
            hint={"All Model"}
            isSearchable={true}
            containerStyle={style["selectable-button-container-style"]}
            selectClass={style["filterDropDown"]}
          />

          {/* <MultiSelect
            name="varientName"
            handleChange={(e) =>
              setFilter({ ...filter, varientName: e?.value })
            }
            items={varientList?.map((ele) => ({
              label: ele?.variantName,
              value: ele?.variantId,
            }))}
            selectedItems={filter?.varientName}
            hint={"All Varient"}
            containerStyle={style["selectable-button-container-style"]}
            selectClass={style["filterDropDown"]}
          /> */}

          <MultiSelect
            name="status"
            handleChange={(e) => setFilter({ ...filter, status: e?.value })}
            items={[
              { label: "Active", value: "1" },
              { label: "InActive", value: "0" },
              { label: "Booked", value: "2" },
              { label: "Sold", value: "3" },
            ]}
            selectedItems={filter?.status}
            hint={"All Status"}
            containerStyle={style["selectable-button-container-style"]}
            selectClass={style["filterDropDown"]}
          />
        </div>

        <div className={style["action-buttons-wrapper"]}>
          <PrimaryButton
            containerStyle={style["add-new-car-button-container-style"]}
            leadingIcon={R.ic_add_white}
            name={"Add New Car"}
            fontSize={"1rem"}
            linkTo={"/add-new-car"}
            onClick={() => {}}
          />

          {/* <PrimaryButton
            containerStyle={style["excel-button-container-style"]}
            leadingIcon={R.ic_excel}
            name={"Export Excel"}
            fontSize={"1rem"}
            onClick={exportToExcel}
          /> */}
        </div>
      </div>

      <div className={style["table-pagination-container"]}>
        <p className={style["items-count"]}>{/* Rows : {carList.length} */}</p>
        <div className={style["pagination-action-wrapper"]}>
          <button onClick={handlePreviousPage} disabled={currentPage === 1}>
            Back
          </button>
          <p>
            Page {currentPage} of {totalPageCount}
          </p>

          <button onClick={handleNextPage} disabled={!hasNextPage}>
            Next
          </button>
        </div>
      </div>

      <DetailTable data={data} setData={setData} />

      <div className={style["table-pagination-container"]}>
        <p className={style["items-count"]}>
          {/* Rows : {carList.length} */}
          {/* {currentPage} of {totalPageCount} */}
        </p>
        <div className={style["pagination-action-wrapper"]}>
          <button onClick={handlePreviousPage} disabled={currentPage === 1}>
            Back
          </button>
          <p>
            Page {currentPage} of {totalPageCount}
          </p>

          <button onClick={handleNextPage} disabled={!hasNextPage}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
